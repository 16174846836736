import {EventEmitter, Injectable} from '@angular/core';
import * as moment from 'moment';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {SignInDatabase} from './authorization/sign-in/datasource/sign-in.database';
import {StateService} from './core/providers/state.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private lastOperationTime: number;

  openSessionPopup = new EventEmitter(true);
  heartbeatSub: Subscription;

  // interval = 10000;
  interval = 30 * 1000; // 30 seconds
  // intervalSinceLastOperation = 10000;
  intervalSinceLastOperation = 10 * 60 * 1000; // 10 minutes

  private authorizationToken = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private singInBase: SignInDatabase,
    private stateService: StateService
  ) {
    if (localStorage.getItem('Authorization')) {
      this.startHeartBeating();
    }
  }

  startHeartBeating() {
    console.log('startHeartBeating: ' + moment().format());
    const lastHeartbeat = localStorage.getItem('last_heartbeat');
    if (lastHeartbeat) {
      this.lastOperationTime = parseInt(lastHeartbeat);
    }
    const currentMoment = moment().valueOf();
    if (currentMoment - this.lastOperationTime > this.intervalSinceLastOperation) {
      this.closeUserSession();
      return;
    }
    if (!this.heartbeatSub) {
      this.heartbeatSub = interval(this.interval).subscribe(() => {
        const currentMoment = moment().valueOf();
        if (currentMoment - this.lastOperationTime > this.intervalSinceLastOperation) {
          this.stopHeartBeating();
          this.openSessionPopup.emit();
          console.log('openSessionPopup: ' + moment().format());
        }
      });
    }
  }

  stopHeartBeating() {
    if (this.heartbeatSub) {
      this.heartbeatSub.unsubscribe();
      this.heartbeatSub = null;
    }
  }

  keepSession() {
    this.heartbeat();
    this.startHeartBeating();
  }

  heartbeat() {
    console.log('heartbeat: ' + moment().format());
    this.lastOperationTime = moment().valueOf();
    localStorage.setItem('last_heartbeat', this.lastOperationTime.toString(10));
  }

  closeUserSession() {
    // this.singInBase.logOut().subscribe(res => {
    //   this.stateService.clearUserProfile();
    //   this.router.navigate(['authorization', 'login']).then();
    // });
    console.log('closeUserSession: ' + moment().format());
    this.stateService.clearUserProfile();
    localStorage.removeItem('Authorization');
    this.setAuthorizationToken(null);
    this.router.navigate(['authorization', 'login']).then();
  }

  setAuthorizationToken(value) {
    this.authorizationToken.next(value);
  }

  subscribeAuthorizationToken() {
    return this.authorizationToken.asObservable();
  }
}
