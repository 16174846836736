import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {HttpWrapper} from "../models/http-wrapper";
import {ActivationStatusCheckModel} from "../models/activation-status-check.model";
import {ApiConst} from "../../app.constants";
import {BehaviorSubject, Observable} from "rxjs";
import {CanAccessModel} from "../models/can-access.model";

@Injectable({
  providedIn: 'root'
})
export class ActivationCheckService {
  public canAccess: BehaviorSubject<CanAccessModel> = new BehaviorSubject<CanAccessModel>({access: false, requestedType: null, isRead: false});

  constructor(private httpClient: HttpClient, private router: Router) {
  }

  public checkActivationStatus(): Observable<HttpWrapper<ActivationStatusCheckModel>> {

    let url;
    if (this.router.url.split('/').length > 2) {
      if (this.router.url.includes('automatic-booking')) {
        url = 'automatic_booking';
      } else {
        url = this.router.url.split('/')[1];
      }
    } else {
      url = this.router.url.slice(1);
    }
    if (url === 'calls') {
      url = 'chat';
    }
    if (url === 'waiting-rooms') {
      url = 'waiting_rooms';
    }
    const body = {
      perm: url,
    }
    return this.httpClient.post<HttpWrapper<ActivationStatusCheckModel>>(`${ApiConst.baseUrl}/license/permissions`, body);
    // return this.httpClient.post<HttpWrapper<ActivationStatusCheckModel>>(`https://4c33ee15612b.ngrok.io/permissions`, body);
  }
}
