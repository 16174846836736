import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ApiConst} from '../../app.constants';
import {CanAccessModel} from "../models/can-access.model";
import {catchError, switchMap} from "rxjs/operators";
import {HttpWrapper} from "../models/http-wrapper";
import {ActivationCheckService} from "../providers/activation-check.service";

@Injectable({
  providedIn: 'root'
})
export class CheckPaymentGuard implements CanActivate {

  constructor(private httpClient: HttpClient, private router: Router, private activationCheckService: ActivationCheckService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const urlComponents = state.url.split('/');
    let url = (urlComponents.length > 1 ? urlComponents[1] : '');

    if (url === 'account') {
      url = urlComponents[2];
    }

    //removes query params
    if (url.indexOf("?") > 0) {
      url = url.substring(0, url.indexOf("?"));
    }

    if (urlComponents.includes('account')) {
      url = urlComponents[2];
      // this.activationCheckService.canAccess.next({
      //   access: true,
      //   requestedType: null, //'requested',
      //   message: '',
      //   isRead: true
      // });
      // return true;
    }



    return this.httpClient.get<HttpWrapper<CanAccessModel>>(`${ApiConst.baseUrl}/license/permissions?perm=${url.replace('-', '_')}`)
      .pipe(
        switchMap((e: HttpWrapper<CanAccessModel>) => {
          if (!e.error_message) {
            this.activationCheckService.canAccess.next(e.data);
            state.root.data = e.data;
            if (!e.data.access && state.url.split('/').length > 3) {
              return of(this.router.createUrlTree([...urlComponents.filter(item => item.length > 0)]));
            } else {
              return of(true);
            }
          } else {
            return of(false);
          }
        }),
        catchError((error: HttpErrorResponse, caught) => {
          if (error.status === 500) {
            return of(false);
          } else {
            return throwError(() => {
              return error;
            });
          }
        }),
      );
  }
}
