import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router} from '@angular/router';
import {StateService} from './core/providers/state.service';
import {CallApiService} from './core/api/call-api.service';
import {SessionService} from './session.service';
import {SwalComponent, SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';
import {interval, Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from './core/providers/ui.service';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'atk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CallApiService]
})
export class AppComponent implements OnInit {

  @ViewChild('session', {static: false}) sessionPopup: SwalComponent;

  public currentUrl: string[] = [];
  showMenu = false;
  url;
  sessionTimer: number;
  sessionTimerText: string;
  sessionTimerSub: Subscription;

  public showProgressBar$: Observable<boolean>;

  user = null;
  token = null;

  showDocumentRequirements = false;

  constructor(
    private appService: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private callBase: CallApiService,
    private stateService: StateService,
    public readonly swalTargets: SwalPortalTargets,
    private readonly uiService: UiService,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'dn', 'gr', 'fr', 'sw', 'sp']);
    translate.setDefaultLang(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
    translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');

    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   }
    // );
  }

  public ngOnInit() {
    this.appService.setAuthorizationToken(localStorage.getItem('Authorization'));
    this.appService.subscribeAuthorizationToken().subscribe(value => {
      this.token = value;
    });
    this.router.events.subscribe((event) => {
      this.appService.heartbeat();
      if (event instanceof ActivationEnd && event.snapshot.children.length === 0) {
        if (event.snapshot.data.hasOwnProperty('fullView')) {
          const fullView = event.snapshot.data['fullView'];
          this.showMenu = !fullView;
        } else {
          this.showMenu = true;
        }
      }
      if (event instanceof NavigationEnd) {
        this.currentUrl = [...event.urlAfterRedirects.split('/')];
        this.stateService.userProfile.subscribe(result => {
          this.user = result;
        });
      }
    });

    this.appService.openSessionPopup.subscribe(() => {
      this.showSessionPopup();
    });

    this.getUiState();
  }

  private getUiState(): void {
    this.showProgressBar$ = this.uiService.getProgressBarState();
  }

  showSessionPopup() {
    this.sessionTimer = 120000;
    // this.sessionTimer = 10000;
    this.sessionTimerText = '2:00';
    this.sessionTimerSub = interval(1000).subscribe(() => {
      this.sessionTimer -= 1000;
      this.sessionTimerText = Math.floor(this.sessionTimer / 60000) + ':' +
        ('0' + (this.sessionTimer % 60000) / 1000).slice(-2);
      if (this.sessionTimer <= 0 && this.sessionTimerSub && !this.sessionTimerSub.closed) {
        this.sessionPopup.close().then();
        this.appService.closeUserSession();
      }
    });

    this.sessionPopup.swalOptions = {
      title: 'Session',
      confirmButtonColor: '#42a3ed',
      confirmButtonText: 'Keep session',
      showConfirmButton: true,
      cancelButtonText: 'Logout',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    };
    this.sessionPopup.fire().then(result => {
      if (this.sessionTimerSub && !this.sessionTimerSub.closed) {
        this.sessionTimerSub.unsubscribe();
      }
      if (result.isConfirmed) {
        this.appService.keepSession();
      } else {
        this.appService.closeUserSession();
      }
    });
  }

}
