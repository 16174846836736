import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {interval} from 'rxjs';
import {NotificationModel} from '../models/notifications/notification.model';

@Component({
  selector: 'atk-notification-cancelled-call',
  templateUrl: './notification-cancelled-call.component.html',
  styleUrls: ['./notification-cancelled-call.component.scss'],
})

export class NotificationCancelledCallComponent implements OnInit {

  @ViewChild('container', {static: true}) containerElement: ElementRef<HTMLDivElement>;

  @Input() notification: NotificationModel;
  @Output() close = new EventEmitter<number>();
  @Output() redial = new EventEmitter<{
    notificationId: number,
    callType: 'audio' | 'video'
  }>();
  opacity = 0;


  constructor() {
  }

  ngOnInit() {
    if (this.notification.timeout) {
      const timeoutSubscription = interval(this.notification.timeout).subscribe(() => {
        timeoutSubscription.unsubscribe();
        this.close.emit(this.notification.id);
      })
    }
  }


  redialAudioClick() {
    this.redial.emit({
      notificationId: this.notification.id,
      callType: 'audio'
    });
  }
  redialVideoClick() {
    this.redial.emit({
      notificationId: this.notification.id,
      callType: 'video'
    });
  }
}
