
<div class="notification-container">
  <div *ngFor="let notification of notifications" [@notificationShowClose]="'show'">
    <atk-notification-message
      *ngIf="notification.notificationType === 'message'"
      (click)="notificationClick(notification)"
      [notification]="notification"
      (close)="notificationClosed($event)"
      (markAsRead)="markAsReadClick(notification)"
    ></atk-notification-message>
    <atk-notification-cancelled-call
      *ngIf="notification.notificationType === 'cancelled_call'"
      (click)="notificationClick(notification)"
      (redial)="redialClick($event, notification)"
      [notification]="notification"
      (close)="notificationClosed($event)"
    ></atk-notification-cancelled-call>
    <atk-notification-system
      *ngIf="notification.notificationType === 'system'"
      (click)="notificationClick(notification)"
      [notification]="notification"
      (close)="notificationClosed($event)"
    ></atk-notification-system>
  </div>
</div>


<!-- <swal #call>
  <ng-container *swalPortal>
    <div class="incoming-call-container">
      <img *ngIf="incomingCall.user.photo" class="user-photo"  [src]="incomingCall.user.photo" alt="">
      <span *ngIf="!incomingCall.user.photo" class="user-no-photo" inlineSVG="assets/svg/ic_user_photo_empty.svg"></span>

      <div class="user-info-container">
        <div class="user-name">{{incomingCall.user.firstName}} {{incomingCall.user.lastName}}</div>
        <div class="user-action">{{'chat.isCalling' | translate}}</div>
        <div class="user-action">{{incomingCall.isVideo ? 'Video' : 'Audio'}} call</div>
      </div>

      <div class="button-container">
        <button mat-icon-button class="dismiss-call" (click)="incomingCallDismissed()">
          <span inlineSVG="assets/svg/ic_end_call.svg"></span>
        </button>

        <button mat-icon-button class="answer-call" (click)="incomingCallAnswered()">
          <span inlineSVG="assets/svg/ic_call.svg"></span>
        </button>
      </div>
    </div>
  </ng-container>
</swal> -->
<swal #call>
  <ng-container *swalPortal>
    <div class="incoming-call-container">
      <img *ngIf="incomingCall.user.photo" class="user-photo"  [src]="incomingCall.user.photo" alt="">
      <span *ngIf="!incomingCall.user.photo" class="user-no-photo" inlineSVG="assets/svg/ic_user_photo_empty.svg"></span>


        <div class="user-name">{{incomingCall.user.firstName}} {{incomingCall.user.lastName}}</div>

      <div class="button-container">
        <button mat-icon-button class="dismiss-call" (click)="incomingCallDismissed()">
          <span inlineSVG="assets/svg/ic_end_call.svg"></span>
        </button>

        <button mat-icon-button class="answer-call" (click)="incomingCallAnswered()">
          <span inlineSVG="assets/svg/ic_call.svg"></span>
        </button>
      </div>
    </div>
  </ng-container>
</swal>