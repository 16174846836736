import {Injectable} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {HttpWrapper, HttpWrapperArray, HttpWrapperCollection} from '../models/http-wrapper';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiConst} from '../../app.constants';
import {TitleModel} from '../models/title.model';
import {AbstractUserModel} from '../models/abstract-user.model';
import {ClinicModel, ICLinicType, IProfessionalInfoPaginatedParams, AttachmentListModel} from '../models/clinic.model';
import {DepartmentAbstractModel} from '../models/department-abstract.model';
import {SuccessResponseModel} from '../models/success-response.model';
import {ScheduleModel} from '../models/schedule.model';
import {IdResponseModel} from '../models/id-response.model';
import {ProfessionalModel} from '../models/professional.model';
import {UserModel} from '../models/user.model';
import {SpecializationModel} from '../models/specialization.model';
import {RoleModel} from '../models/role.model';
import {MonitoringSectionModel} from '../models/monitoring-section.model';
import {TelephoneModel} from '../models/telephone.model';
import {ProfileModel} from '../models/profile.model';
import {MedicalSpecializationModel} from '../models/medical-specialization.model';
import {TrackerModel} from '../models/tracker.model';


@Injectable({
  providedIn: 'root'
})
export class ClinicsService {

  clinicsInformationUpdated = new BehaviorSubject(false);
  monitoringInformationUpdated = new BehaviorSubject(false);

  constructor(private api: HttpClient) {}

  public getClinicsTypes(): Observable<ICLinicType> {
    return this.api.get<ICLinicType>(`${ApiConst.baseUrl}/api/v3/clinics/types`);
  }

  getClinicsSpecializations() {
    return this.api.get<HttpWrapperArray<MedicalSpecializationModel>>(`${ApiConst.baseUrl}/clinics/specializations`);
  }

  getLevels() {
    return this.api.get<HttpWrapperArray<TitleModel>>(`${ApiConst.baseUrl}/clinics/levels`);
  }

  public createClinicNew(body): Observable<any> {
    return this.api.post(`${ApiConst.baseUrl}/api/v3/clinics/raw`, body);
  }

  createClinic(body) {
    return this.api.post<HttpWrapper<{
      id: string
    }>>(`${ApiConst.baseUrl}/clinics/upsert`, body);
  }

  upsertClinic(id: string, body) {
    body.id = id;

    return this.api.patch<HttpWrapper<ClinicModel>>(`${ApiConst.baseUrl}/clinics/upsert`, body);
  }

  public multiInvites(params) {

    return this.api.post(`${ApiConst.baseUrl}/cloud/invites/patient/multi`, params);
  }

  getMonitoringList(
    params: {
      clinicsId: string;
    }) {

    const httpParams = new HttpParams({
      fromObject: {
        clinic_id: params.clinicsId,
      }
    });

    return this.api.get<HttpWrapperArray<MonitoringSectionModel>>(
      `${ApiConst.baseUrl}/clinics/monitoring`, {
        params: httpParams
      });
  }

  getClinicsDetails(clinicsId: string) {
    return this.api.get<HttpWrapper<ClinicModel>>(`${ApiConst.baseUrl}/clinics/details/${clinicsId}`)
  }

  retrievePatientsList(monitoringId: number): Promise<AbstractUserModel[]> {
    return new Promise<AbstractUserModel[]>((resolve, reject) => {
      this.getMonitoringPatients(monitoringId).subscribe(patientsList => {
        if (patientsList.status === 'ok') {
          resolve(patientsList.data);
        } else {
          reject(patientsList);
        }
      });
    });
  }

  private getMonitoringPatients(monitoring_area_id: number, limit: number = Number.MAX_SAFE_INTEGER, offset: number = 0):
    Observable<HttpWrapperArray<AbstractUserModel>> {
    const params = new HttpParams({
      fromObject: {
        monitoring_area_id: monitoring_area_id.toString(10),
        limit: limit.toString(10),
        offset: offset.toString(10)
      }
    });
    return this.api.get<HttpWrapperArray<AbstractUserModel>>(
      `${ApiConst.baseUrl}/clinics/monitoring/patients`, {
        params
      });
  }

  getDepartments(
    params: {
      clinic_id: string;
    }) {

    const httpParams = new HttpParams({
      fromObject: {
        clinic_id: params.clinic_id,
      }
    });

    return this.api.get<HttpWrapperArray<DepartmentAbstractModel>>(
      `${ApiConst.baseUrl}/clinics/departments`, {
        params: httpParams
      });
  }

  getMonitoringDoctors(monitoring_area_id: number) {

    const params = new HttpParams().append('monitoring_area_id', monitoring_area_id.toString(10));
    return this.api.get<HttpWrapperArray<AbstractUserModel>>(
      `${ApiConst.baseUrl}/clinics/monitoring/staff`, {params});
  }

  moveDoctorToMonitoring(doctorId: string, monitoringId: number) {
    const params = {
      target_id: doctorId,
      monitoring_area_id: monitoringId
    };
    return this.api.post<HttpWrapperArray<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/monitoring/staff`, params);
  }

  kickDoctorFromMonitoring(doctorId: string, monitoringId) {

    const params = {
      target_id: doctorId,
      monitoring_area_id: monitoringId,
    };
    const options = {
      headers: {},
      body: params
    };
    return this.api.delete<HttpWrapperArray<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/monitoring/staff`, options);
  }

  referPatientToMonitoring(monitoringId: number, patientId: string) {
    const params = {
      monitoring_area_id: monitoringId,
      target_id: patientId,
    };

    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/monitoring/patients`, params);
  }

  releasePatient(monitoringId: number, patientId: string) {
    const params = {
      monitoring_area_id: monitoringId,
      target_id: patientId,
    };
    const options = {
      headers: {},
      body: params
    };
    return this.api.delete<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/monitoring/patients`, options);
  }

  createMonitoring(params: {
    clinic_id: string,
    name: string,
    email: string,
    telephone: TelephoneModel,
    attachment_id: string,
    // booking_only: monitoring.booking_only,
    // per_invitation_only: waitingRoom.booking_only,
    schedules: ScheduleModel[],
    medical_specialization_id: number,
  }) {

    return this.api.post<HttpWrapper<IdResponseModel>>(`${ApiConst.baseUrl}/clinics/monitoring`, params);
  }

  removeMonitoring(params) {

    const options = {
      headers: {},
      body: params
    };
    return this.api.delete<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/monitoring`, options);
  }

  editMonitoring(params: {
    id: number,
    clinic_id: string,
    name: string,
    email: string,
    telephone: TelephoneModel,
    attachment_id: string,
    // booking_only: monitoring.booking_only,
    // per_invitation_only: waitingRoom.booking_only,
    schedules: ScheduleModel[],
    medical_specialization_id: number,
  }) {

    return this.api.put<HttpWrapper<IdResponseModel>>(`${ApiConst.baseUrl}/clinics/monitoring`, params);
  }

  createDepartment(params: {
    clinic_id: string,
    title: string,
    email: string,
    telephone: TelephoneModel,
    attachment_id: string,
    // booking_only: monitoring.booking_only,
    // per_invitation_only: waitingRoom.booking_only,
    schedules: ScheduleModel[],
    medical_specialization_id: number,
  }) {

    return this.api.post<HttpWrapper<IdResponseModel>>(`${ApiConst.baseUrl}/clinics/departments`, params);
  }

  removeDepartment(params) {

    const options = {
      headers: {},
      body: params
    };
    return this.api.delete<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/departments`, options);
  }

  editDepartment(params: {
    id: number
    clinic_id: string,
    title: string,
    email: string,
    telephone: TelephoneModel,
    attachment_id: string,
    // booking_only: monitoring.booking_only,
    // per_invitation_only: waitingRoom.booking_only,
    schedules: ScheduleModel[],
    medical_specialization_id: number,
  }) {
    return this.api.put<HttpWrapper<IdResponseModel>>(`${ApiConst.baseUrl}/clinics/departments`, params);
  }

  moveDoctorToDepartment(doctorId: string, departmentId: number) {
    const params = {
      doctor_id: doctorId,
      department_id: departmentId
    };
    return this.api.post<HttpWrapperArray<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/change/department`, params);
  }

  getProfessionalsPaginatedUpdated(params: IProfessionalInfoPaginatedParams): Observable<HttpWrapperCollection<ProfessionalModel>> {
    const httpParams = new HttpParams({ fromObject: { ...params } });

    return this.api.get<HttpWrapperCollection<ProfessionalModel>>(`${ApiConst.baseUrl}/clinics/professionals/paginated`, {
      params: httpParams
    });
  }

  getProfessionals(params: {
    clinicsId: string
    search?: string
    page?: number
    count?: number
  }) {
    let httpParams = new HttpParams({
      fromObject: {
        clinic_id: params.clinicsId,
        search: params.search || '',
      }
    });
    if (params.page && params.count) {
      httpParams = httpParams.append('page', params.page.toString(10));
      httpParams = httpParams.append('count', params.count.toString(10));
    }
    return this.api.get<HttpWrapperArray<ProfessionalModel>>(
      `${ApiConst.baseUrl}/clinics/professionals`, {
        params: httpParams
      })
  }

  getProfessionalsPaginated(params: {
    clinicId: string
    search?: string
    limit?: number
    offset?: number
  }) {

    let httpParams = new HttpParams({
      fromObject: {
        clinic_id: params.clinicId,
        search: params.search || '',
      }
    });

    if (params.limit !== null && params.offset !== null) {
      httpParams = httpParams.append('limit', params.limit.toString(10));
      httpParams = httpParams.append('offset', params.offset.toString(10));
    }

    return this.api.get<HttpWrapperCollection<ProfessionalModel>>(
      `${ApiConst.baseUrl}/clinics/professionals/paginated`, {
        params: httpParams
      });
  }

  getProfessionalIds(params: {
    clinicId: string
  }) {

    let httpParams = new HttpParams({
      fromObject: {
        clinic_id: params.clinicId,
      }
    });

    return this.api.get<HttpWrapperArray<string>>(
      `${ApiConst.baseUrl}/clinics/clinic/doctors`, {
        params: httpParams
      });
  }

  getPatients(params: {
    clinic_id: string
    search?: string
    limit?: number
    offset?: number
  }) {
    return this.api.post<HttpWrapperArray<UserModel>>(`${ApiConst.baseUrl}/clinics/patients`, params);
  }

  getPatientsCount(params: {
    clinic_id: string
    search?: string
  }) {
    return this.api.post<HttpWrapper<string>>(`${ApiConst.baseUrl}/clinics/patients/count`, params);
  }

  getClinics(params: {
    types?: number[],
    specializations?: number[],
    search?: string,
    page?: number,
    count?: number,
    country_iso3_code?: string
  }) {

    return this.api.post<HttpWrapperArray<ClinicModel>>(
      `${ApiConst.baseUrl}/clinics/list`, params);
  }

  getPersonalClinics() {
    return this.api.get<HttpWrapperArray<SpecializationModel>>(
      `${ApiConst.baseUrl}/clinics/specializations/personal`);
  }

  changeActiveClinic(clinicId: string) {
    const params = {
      clinic_id: clinicId
    };

    return this.api.post<HttpWrapper<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/change/apply`, params);
  }

  public checkInClinic(params) {
    return this.api.post(`${ApiConst.baseUrl}/clinics/check-in`, params);
  }
  public checkInClinicUpdate(params) {
    return this.api.put(`${ApiConst.baseUrl}/clinics/check-in`, params);
  }

  public checkInClinicById(clinicId: string): Observable<HttpWrapper<ProfileModel>> {
    return this.api.post<HttpWrapper<ProfileModel>>(`${ApiConst.baseUrl}/api/v3/clinics/check-in`, { clinicId });
  }

  getDoctorSpecializations(params: {
    user_id: string
    clinic_id?: string,
    medical_specialization_id?: number
  }) {
    return this.api.post<HttpWrapperArray<SpecializationModel>>(`${ApiConst.baseUrl}/clinics/specializations/list`, params);
  }

  getSubRoles() {
    return this.api.get<HttpWrapperArray<RoleModel>>(`${ApiConst.baseUrl}/clinics/sub_roles`);
  }

  public getClinicsMedicalRoles(clinicId: string): Observable<any> {
    return this.api.get(`${ApiConst.baseUrl}/clinics/sub_roles?clinic_id=${clinicId}`);
  }

  uploadClinicIdDocument(params: {
    attachment_id: string, clinic_id?: string
  }) {
    return this.api.post<HttpWrapper<any>>(`${ApiConst.baseUrl}/clinics/attachments`, params);
  }

  public getClinicAttachments(clinicId) {
    const params = new HttpParams().append('clinic_id', clinicId);
    return this.api.get<HttpWrapperArray<AttachmentListModel>>(`${ApiConst.baseUrl}/clinics/attachments`, {params});
  }

  getTrackers(): Observable<HttpWrapperArray<TrackerModel>> {
    return this.api.get<HttpWrapperArray<TrackerModel>>(`${ApiConst.baseUrl}/clinics/trackers`, {
      params: {
        category: 1
      }
    });
  }
}
