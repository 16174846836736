import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {LayoutModule} from '@angular/cdk/layout';
import {throwIfAlreadyLoaded} from './module-import.guard';
import {StateService} from './providers/state.service';
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    LayoutModule,
    SweetAlert2Module,
  ],
  declarations: [],
  providers: [
    StateService
  ],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
