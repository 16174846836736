import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppotekDropdownComponent} from './dropdown/appotek-dropdown.component';
import {FormsModule} from '@angular/forms';
import {InlineSVGModule} from 'ng-inline-svg';
import {PaginatorComponent} from './paginator/paginator.component';
import {MatSelectModule} from '@angular/material/select';
import {PaginationModule} from '../pagination/pagination.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    AppotekDropdownComponent,
    PaginatorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InlineSVGModule,
    MatSelectModule,
    PaginationModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule
  ],
  exports: [
    AppotekDropdownComponent,
    PaginatorComponent
  ]
})
export class ControlsModule {
}

