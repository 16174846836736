import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConst} from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class CallApiService {
  constructor(private api: HttpClient) {
  }

  public startCall(params) {

    return this.api.post(`${ApiConst.baseUrl}/webrtcapi/api/calls/start-call`, params);
  }

  public joinCall(params) {

    return this.api.post(`${ApiConst.baseUrl}/webrtcapi/api/calls/join-call`, params);
  }

  public ignoreCALL(params) {

    return this.api.post(`${ApiConst.baseUrl}/webrtcapi/api/calls/ignore-call`, params);
  }

  public cancelCALL(params) {

    return this.api.post(`${ApiConst.baseUrl}/webrtcapi/api/calls/cancel-call`, params);
  }

  public updateDevice(params) {

    return this.api.patch(`${ApiConst.baseUrl}/api/v3/devices`, params);
  }

  public callHistory(params) {

    return this.api.post(`${ApiConst.baseUrl}/api/v1/call-history`, params);
  }

  public initCall(params) {

    return this.api.post(`${ApiConst.baseCallUrl}/api/v1/start-call`, params);
  }

  public receiveCall(params) {

    return this.api.post(`${ApiConst.baseCallUrl}/api/v1/receive-call`, params);
  }

  public cancelCall(params) {

    return this.api.post(`${ApiConst.baseCallUrl}/api/v1/cancel-call`, params);
  }

  public ignoreCall(params) {

    return this.api.post(`${ApiConst.baseCallUrl}/api/v1/ignore-call`, params);
  }

  public endCall(params) {

    return this.api.post(`${ApiConst.baseCallUrl}/api/v1/end-call`, params);
  }
  // Deprecated
  // public callAcceptDecline(params) {
  //   return this.api.put(`${ApiConst.baseUrl}/clinics/waiting_rooms/call/request/status/change`, params);
  // }
  // public requestCall(params) {
  //   return this.api.post(`${ApiConst.baseUrl}/clinics/waiting_rooms/call/request/list`, params);
  // }
  // event-call api
  // public requestEventCall(eventId:number) {
  //   return this.api.get(`${ApiConst.baseUrl}/clinics/event/calls/request?event_id=${eventId}`);
  // }

  // public eventCallApprove(params) {
  //   return this.api.put(`${ApiConst.baseUrl}/clinics/event/calls/request`, params);
  // }
}
