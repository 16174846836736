import {Injectable, NgZone} from '@angular/core';
import {take} from 'rxjs/operators';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {StateService} from '../providers/state.service';
import {UserService} from '../providers/user.service';
import {NotificationCancelledCallModel} from './models/notifications/notification-cancelled-call.model';
import {getFullName} from '../../helpers/utils';
import {ChatMessageModel} from '../models/chat-message.model';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {UserModel} from '../models/user.model';
import {IUsersNotification} from './models/notifications/notification.model';
import {NotificationMessageModel} from './models/notifications/notification-message.model';
import {NotificationSystemModel} from './models/notifications/notification-system.model';
import {CallApiService} from '../api/call-api.service';
import {HttpClient} from '@angular/common/http';
import {ApiConst} from '../../app.constants';
import {HttpWrapper, HttpWrapperCollection} from '../models/http-wrapper';
import {SignInDatabase} from '../../authorization/sign-in/datasource/sign-in.database';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  registrationToken: string;

  private incomingCallPopup = new BehaviorSubject<{
    show: boolean,
    user: UserModel,
    video: boolean,
    roomId: string
  }>({
    show: false,
    user: null,
    video: false,
    roomId: ''
  });


  private notificationQueue = new BehaviorSubject<(NotificationMessageModel |
    NotificationCancelledCallModel |
    NotificationSystemModel)[]>([]);

  incomingCallPopup$ = this.incomingCallPopup.asObservable();
  notificationQueue$ = this.notificationQueue.asObservable();
  private lastNotificationId = 0;

  private ignoredCalls = new Subject<{ roomId: string }>();
  ignoredCalls$ = this.ignoredCalls.asObservable();


  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private stateService: StateService,
    private userService: UserService,
    private callApiService: CallApiService,
    private zone: NgZone,
    private readonly http: HttpClient,
    private signInService: SignInDatabase
  ) {

  }

  init() {
    this.signInService.userLoggedIn$.subscribe(next => {
      this.requestToken();
    });
    this.requestToken();
    this.angularFireMessaging.messages.subscribe(message => {
      console.log('PUSH NOTIFICATION', message);
      this.zone.run(() => {
        this.prepareNotifications(message);
      });
    });
  }

  requestToken() {
    this.angularFireMessaging.requestToken.pipe(take(1)).subscribe(token => {
      this.registrationToken = token;
      // Check if user is logged in
      if (localStorage.getItem('Authorization')) {
        this.callApiService.updateDevice({
          fcmToken: token
        }).subscribe(_ => {
        });
      }
    });
  }

  prepareNotifications(message: any) {
    if (message.hasOwnProperty('data') && message['data'].hasOwnProperty('type')) {
      const pushData = message['data'];
      const pushType = pushData['type'];
      this.notificationOperateFactory(pushType, pushData);
    } else {
      const notificationTitle = message.notification.title;
      const notificationBody = message.notification.body;
      new Notification(notificationTitle, {
        body: notificationBody
      });
    }
  }

  notificationOperateFactory(pushType: string, pushData) {
    switch (pushType) {
      case 'incoming_call':
        this.onIncomingCallNotification(pushData);
        break;
      case 'cancelled_call':
        this.onCancelledCallNotification(pushData);
        break;
      case 'ignored_call':
        this.onIgnoredCallNotification(pushData);
        break;
      case 'dialog':
        this.onDialogNotification(pushData);
        break;
      default:
        // this.store.dispatch(new AddNotificationToQueue(pushData as NotificationSystemModel));
        break;
    }
  }

  onIncomingCallNotification(pushData) {
    this.userService.getUserById(pushData.userId).subscribe(userResponse => {
      if (userResponse.status === 'ok') {
        this.incomingCallPopup.next({
          show: true,
          user: userResponse.data,
          video: pushData['callType'] === 'video',
          roomId: pushData['roomId']
        });
      }
    });
  }

  onCancelledCallNotification(pushData) {
    this.userService.getUserById(pushData.userId).subscribe(userResponse => {
      if (userResponse.status === 'ok') {
        this.incomingCallPopup.next({
          show: false,
          user: null,
          video: false,
          roomId: ''
        });
        this.notificationQueue.next([
          ...this.notificationQueue.getValue(),
          {
            id: ++this.lastNotificationId,
            message: `You missed call from ${getFullName(userResponse.data)}`,
            notificationType: 'cancelled_call',
            roomId: pushData['roomId'],
            userId: pushData['userId'],
            timeout: 15000,
            title: 'Missed call',
            type: 'error',
          } as NotificationCancelledCallModel
        ]);
      }
    });
  }

  onIgnoredCallNotification(pushData) {
    this.ignoredCalls.next({
      roomId: pushData['roomId']
    });
  }

  onDialogNotification(pushData) {
    const message = JSON.parse(pushData['message']) as ChatMessageModel;
    const userId = message.created_by;
    this.userService.getUserById(userId).subscribe(userResponse => {
      if (userResponse.status === 'ok') {
        this.notificationQueue.next([
          ...this.notificationQueue.getValue(),
          {
            id: ++this.lastNotificationId,
            timeout: 0,
            message: message.text,
            title: getFullName(userResponse.data),
            notificationType: 'message',
            type: 'info',
            messageId: parseInt(message.id.toString(), 10),
            roomId: parseInt(message.room_id.toString(), 10)
          } as NotificationMessageModel
        ]);
      }
    });
  }


  removeNotificationsFromQueue(notificationId: number) {
    const notifications = this.notificationQueue.getValue();
    const newNotifications = notifications.filter(item => item.id !== notificationId);
    this.notificationQueue.next(newNotifications);
  }

  setIncomingCallPopup(incomingCallPopupOptions: {
    show: boolean,
    user: UserModel,
    video: boolean,
    roomId: string
  }) {
    this.incomingCallPopup.next(incomingCallPopupOptions);
  }

  public getListOfNotifications(page: number = 1, count: number = 25): Observable<HttpWrapperCollection<IUsersNotification>> {
    return this.http.get<HttpWrapperCollection<IUsersNotification>>(`${ApiConst.baseUrl}/cloud/notifications?page=${page}&count=${count}`);
  }

  public acceptNotification(body): Observable<HttpWrapper<any>> {
    return this.http.post<HttpWrapper<any>>(`${ApiConst.baseUrl}/cloud/notifications/accept`, body);
  }
}
