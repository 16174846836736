import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {interval} from 'rxjs';
import {NotificationModel} from '../models/notifications/notification.model';

@Component({
  selector: 'atk-notification-system',
  templateUrl: './notification-system.component.html',
  styleUrls: ['./notification-system.component.scss'],
})

export class NotificationSystemComponent implements OnInit {

  @ViewChild('container', {static: true}) containerElement: ElementRef<HTMLDivElement>;

  @Input() notification: NotificationModel;
  @Output() close = new EventEmitter<number>();
  opacity = 0;

  constructor() {
  }

  ngOnInit() {
    if (this.notification.timeout) {
      const timeoutSubscription = interval(this.notification.timeout).subscribe(next => {
        timeoutSubscription.unsubscribe();
        this.close.emit(this.notification.id);

      })
    }
  }

}
