import {Inject, Injectable} from '@angular/core';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {WebsocketService} from '../core/websocket/websocket.service';
import {ApiConst} from '../app.constants';
import {WebsocketConnection} from '../core/websocket/websocket-connection';
import {ChatRoomModel} from '../core/models/chat-room.model';
import {ChatMessageModel, ChatMessageRead} from '../core/models/chat-message.model';
import {ChatLocationAttachmentModel} from '../core/models/chat-location-attachment.model';
import {UserService} from '../core/providers/user.service';
import {distinct} from '../helpers/utils';
import {ChatUserModel} from '../core/models/chat-user.model';

@Injectable({
  providedIn: 'root'
})
export class ChatAndCallWebsocketService {
  private messengerWebsocketConnection: WebsocketConnection;

  constructor (
    private websocketService: WebsocketService,
    private userService: UserService
  ) {

  }

  connect(){
    if (!this.messengerWebsocketConnection){
      this.messengerWebsocketConnection = this.websocketService.connect(ApiConst.wssMessenger);
      this.auth(localStorage.getItem('Authorization'));
    }
  }

  disconnect(){
    this.websocketService.disconnect(this.messengerWebsocketConnection);
    this.messengerWebsocketConnection = null;
  }

  auth(token: string) {
    this.messengerWebsocketConnection.sendToken({token: `${token}`})
  }

  onConnection() {
    return this.messengerWebsocketConnection.on<{ userId: string }>(MESSENGER_TYPES.connection).pipe(shareReplay());
  }

  onInvites() {
    return this.messengerWebsocketConnection.on<ChatRoomModel[]>(MESSENGER_TYPES.get_invites).pipe(
      switchMap(invites => {
        if (invites.length > 0) {
          return this.userService.getUsersMinified(invites.map(invite => (invite.invited_by as any) as string)).pipe(
            map(usersResponse => {
              const users = usersResponse.data;
              return invites.map(item => {
                const foundedInviteUserIndex = users.findIndex(user => user.id === ((item.invited_by as any) as string));
                if (foundedInviteUserIndex >= 0) {
                  item.invited_by = users[foundedInviteUserIndex];
                }
                if (item.interlocutor) {
                  const foundedUserIndex = users.findIndex(user => user.id === item.interlocutor.id);
                  if (foundedUserIndex >= 0) {
                    item.interlocutor = {
                      ...item.interlocutor,
                      ...users[foundedUserIndex]
                    };
                  }
                }
                return item;
              });
            })
          );
        } else {
          return of(invites);
        }
      })
    );
  }

  onGetRoom() {
    return this.messengerWebsocketConnection.on<ChatRoomModel[]>(MESSENGER_TYPES.get_rooms).pipe(
      switchMap(rooms => {
        if (rooms.length > 0) {
          const ids = [];
          rooms.forEach(room => {
            if (room.type === 'dialog' && room.interlocutor && !ids.includes(room.interlocutor.id)) {
              ids.push(room.interlocutor.id);
            }
            if (room.type === 'group' && room.participants && room.participants.length > 0) {
              room.participants.forEach(participant => {
                if (!ids.includes(participant.id)) {
                  ids.push(participant.id);
                }
              });
            }
          });
          return this.userService.getUsersMinified(ids)
            .pipe(map(usersResponse => {
              const users = usersResponse.data;
              const filteredRooms = rooms
                .filter(room => room.status !== 'leaved')
                .map(room => ({
                    ...room,
                    id: parseInt(room.id.toString())
                  })
                );

              return filteredRooms.map(room => {
                if (room.type === 'dialog') {
                  if (!room.interlocutor){
                    return null;
                  }
                  return {
                    ...room,
                    interlocutor: {
                      ...room.interlocutor,
                      ...users.filter(user => user.id === room.interlocutor.id)[0]
                    }
                  } as ChatRoomModel;
                }
                if (room.type === 'group') {
                  return {
                    ...room,
                    participants: room.participants.map(participant => ({
                      ...participant,
                      ...users.filter(user => user.id === participant.id)[0]
                    }))
                  };
                }
              }).filter(item => !!(item));
            }));
        } else {
          return of(rooms);
        }
      })
    )
  }

  onGetSupportChat() {
    return this.messengerWebsocketConnection.on<ChatRoomModel[]>(MESSENGER_TYPES.get_support_room).pipe(
      switchMap(rooms => {
        return of(rooms.map(room => ({
          ...room,
          id: parseInt(room.id.toString())
        })));
      })
    );
  }

  onCreateSupportChat() {
    return this.messengerWebsocketConnection.on<ChatRoomModel[]>(MESSENGER_TYPES.create_support_room);
  }

  onGetMessages() {
    return this.messengerWebsocketConnection.on<ChatMessageModel[]>(MESSENGER_TYPES.get_messages).pipe(
      switchMap(messages => {
        return this.formatMessages(messages)
      })
    );
  }

  onCreateDialog() {
    return this.messengerWebsocketConnection.on<{ room_id: number }>(MESSENGER_TYPES.create_dialog);
  }

  onCreateGroup() {
    return this.messengerWebsocketConnection.on<{ room_id: number }>(MESSENGER_TYPES.create_group);
  }

  //
  // onLeaveGroup() {
  //   return this.messengerWebsocketConnection.on<{ room_id: number }>(MESSENGER_TYPES.leave_group);
  // }

  onMessagePublished() {
    return this.messengerWebsocketConnection.on<ChatMessageModel>(MESSENGER_TYPES.message_published).pipe(
      switchMap(message => {
        return this.formatMessages([message]).pipe(
          map(messages => (messages.length > 0 ? messages[0] : null))
        );
      })
    );
  }

  onMessageRead() {
    return this.messengerWebsocketConnection.on<ChatMessageRead>(MESSENGER_TYPES.message_read);
  }

  onMessageUpdated() {
    return this.messengerWebsocketConnection.on<ChatMessageModel>(MESSENGER_TYPES.message_updated).pipe(
      switchMap(message => {
        return this.formatMessages([message]).pipe(
          map(messages => (messages.length > 0 ? messages[0] : null))
        );
      })
    );
  }

  onMessageDeleted() {
    return this.messengerWebsocketConnection.on<ChatMessageModel>(MESSENGER_TYPES.message_deleted).pipe(
      switchMap(message => {
        return this.formatMessages([message]).pipe(
          map(messages => (messages.length > 0 ? messages[0] : null))
        );
      })
    );
  }


  getInvites() {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.get_invites);
  }

  getRooms(params: {
    room_id?: number
  } = null) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.get_rooms, params);
  }

  getSupportRoom() {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.get_support_room);
  }

  createSupportRoom() {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.create_support_room);
  }

  getMessages(params: {
    room_id: number,
    page: number,
    count: number,
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.get_messages, params);
  }

  sendMessage(params: {
    room_id: number,
    text: string
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.send_message, {
      ...params,
      type: 'text'
    });
  }

  sendAttachmentMessage(params: {
    room_id: number,
    shared_attachment_id: string
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.send_message, {
      ...params,
      type: 'file_attachment'
    });
  }

  sendLocationMessage(params: {
    room_id: number,
    location: ChatLocationAttachmentModel
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.send_message, {
      ...params,
      type: 'location_attachment'
    });
  }

  sendContactMessage(params: {
    room_id: number,
    shared_contact_id: string
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.send_message, {
      ...params,
      type: 'contact_attachment'
    });
  }

  readMessage(params: {
    room_id: number,
    message_id: number
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.read_message, params);
  }

  updateMessage(params: {
    id: number,
    room_id: number,
    text: string
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.update_message, params);
  }

  deleteMessage(params: {
    id: number,
    room_id: number,
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.delete_message, params);
  }

  createDialog(params: {
    participants: string[]
  }) {
    this.messengerWebsocketConnection.send(MESSENGER_TYPES.create_dialog, params);
  }

  private formatMessages(messages: ChatMessageModel[]) {
    /*messages = [
      {
        id: 943,
        type: "text",
        text: "test",
        created_by: "6ec87919-0cfb-4cd0-b5e3-7e45ec0ff963",
        created_at: "2021-02-04T20:30:34.043Z",
        placeholderElementId: '',
        shared_attachment: null,
        room_id: 49,
        call_info: null,
        updated_at: '',
      },
      {
        id: 929,
        type: "call_info",
        text: null,
        created_by: "6ec87919-0cfb-4cd0-b5e3-7e45ec0ff963",
        created_at: "2021-02-04T13:56:45.941Z",
        placeholderElementId: '',
        shared_attachment: null,
        room_id: 49,
        updated_at: '',
        call_info: {
          id: "2063cedf-39c7-42f2-b35f-dbccd621ebf6",
          call_type: "video",
          duration: 0,
          status: "cancelled"
        }
      },
      {
        id: 576,
        type: "file_attachment",
        text: null,
        created_by: "6ec87919-0cfb-4cd0-b5e3-7e45ec0ff963",
        created_at: "2021-02-02T17:57:51.798Z",
        placeholderElementId: '',
        shared_attachment:  {
          id: "864f7baa-bc7d-41b1-8d4a-629e66a8dd6e",
          storage_key: "attachments/unnamed-1612288670514-upload.mp4",
          owner_id: "6ec87919-0cfb-4cd0-b5e3-7e45ec0ff963",
          type: "video",
          subtype: "mp4",
          visibility: "public",
          source_url: "https://new-back-storage.s3.eu-central-1.amazonaws.com/attachments/unnamed-1612288670514-upload.mp4",
          uploaded_at: "2021-02-02T17:57:51.720393"
        },
        room_id: 49,
        call_info: null,
        updated_at: '',
      },
      {
        id: 564,
        type: "text",
        text: "hello",
        created_by: "29f58c45-5643-44c8-84b1-c3c6016b9461",
        created_at: "2021-02-02T15:40:40.361Z",
        placeholderElementId: '',
        shared_attachment: null,
        room_id: 49,
        call_info: null,
        updated_at: '',
      },
      {
        id: 563,
        type: "text",
        text: "test",
        created_by: "6ec87919-0cfb-4cd0-b5e3-7e45ec0ff963",
        created_at: "2021-02-02T15:40:32.588Z",
        placeholderElementId: '',
        shared_attachment: null,
        room_id: 49,
        call_info: null,
        updated_at: '',
      },
    ]*/
    messages = messages.map(message => ({
        ...message,
        id: parseInt(message.id.toString(), 10),
        room_id: parseInt(message.room_id.toString(), 10)
      })
    );

    const userIds = messages.map(item => item.created_by).filter(item => !!(item));
    const contactMessageUserIds = messages
      .filter(item => item.type === 'contact_attachment')
      .map(item => item.shared_contact_id);
    userIds.push(...contactMessageUserIds);
    return this.userService.getUsersMinified(distinct(userIds)).pipe(map(usersResponse => {
      const users = usersResponse.data;
      messages = messages.map(message => {
        const foundedUserIndex = users.findIndex(item => item.id === message.created_by);
        if (foundedUserIndex >= 0) {
          message.created_user = users[foundedUserIndex] as ChatUserModel;
        }
        if (message.type === 'contact_attachment') {
          const foundedContactUserIndex = users.findIndex(item => item.id === message.shared_contact_id);
          if (foundedContactUserIndex >= 0) {
            message.shared_contact = users[foundedContactUserIndex]
          }
        }
        return message;
      })
      return messages;
    }));
  }
}

// const CALL_TYPES = {
//   ignored_call: 'ignored_call',
//   incoming_call: 'incoming_call',
//   cancelled_call: 'cancelled_call',
// };

const MESSENGER_TYPES = {
  connection: 'connection',
  get_rooms: 'get_rooms',
  get_support_room: 'get_support_room',
  create_support_room: 'create_support_room',
  get_messages: 'get_messages',
  get_invites: 'get_invites',
  accept_invite: 'accept_invite',
  decline_invite: 'decline_invite',
  invite_feedback: 'invite_feedback',
  send_message: 'send_message',
  update_message: 'update_message',
  delete_message: 'delete_message',
  message_published: 'message_published',
  message_updated: 'message_updated',
  message_deleted: 'message_deleted',
  create_dialog: 'create_dialog',
  create_group: 'create_group',
  room_created: 'room_created',
  room_invited: 'room_invited',
  leave_group: 'leave_group',
  update_room_info: 'update_room_info',
  message_read: 'message_read',
  read_message: 'read_message'
};
