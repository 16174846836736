import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {SessionService} from './session.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {

  constructor(private appService: SessionService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.appService.heartbeat();
    const token = localStorage.getItem('Authorization');
    const existingAuthorization = req.headers.get('Authorization');
    if (!existingAuthorization) {
      if (token) {
        req = req.clone({
          setHeaders: {Authorization: token}
        })
      } else {
        req = req.clone({
          setHeaders: {Authorization: ''}
        })
      }
    }
    return next.handle(req).pipe(catchError((error: HttpErrorResponse, caught) => {
      if (error.status === 401) {
        localStorage.removeItem('Authorization');
        this.appService.setAuthorizationToken(null);
      }
      return throwError(() => {
        return error;
      });
    }));
  }
}
