<div #container class="notification-system-container">
  <button class="close-button" mat-icon-button (click)="close.emit(notification.id)">
    <mat-icon>close</mat-icon>
  </button>
  <div class="notification-header">
    {{notification.title}}
  </div>
  <div class="notification-content">
    {{notification.message}}
  </div>
</div>
