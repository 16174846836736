<div #container class="notification-system-container">
  <div class="notification-header">
    <div>
      <span class="notification-icon" inlineSVG="assets/svg/ic_missed_call.svg"></span>
      <span class="notification-title">{{notification.title}}</span>
    </div>
    <button class="close-button" mat-icon-button (click)="close.emit(notification.id)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="notification-content">
    {{notification.message}}
  </div>
  <div class="button-container">
    <button mat-button (click)="redialAudioClick()">{{'chat.callBackAudio' | translate}}</button>
    <button mat-button (click)="redialVideoClick()">{{'chat.callBackVideo' | translate}}</button>
  </div>
</div>

