import {BreakpointObserver} from '@angular/cdk/layout';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {SignInDatabase} from '../../authorization/sign-in/datasource/sign-in.database';
import {ClinicModel} from '../../core/models/clinic.model';
import {HttpWrapper} from '../../core/models/http-wrapper';
import {UserModel} from '../../core/models/user.model';
import {ClinicsService} from '../../core/providers/clinics.service';
import {StateService} from '../../core/providers/state.service';
import {Location} from '@angular/common';
import {SessionService} from '../../session.service';
import {SpecializationModel} from '../../core/models/specialization.model';
import {MatMenu} from '@angular/material/menu';


@Component({
  selector: 'atk-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, OnDestroy {
  open = true;
  screenWidth = screen.width;
  clinicLogo: string;
  public user: UserModel = null;
  isAdmin = false;
  isSettings = false;
  showSettingOnly = false;
  personalClinics: SpecializationModel[] = [];

  @ViewChild('userMenu', {static: false}) userMenuRef: MatMenu;
  destroy$ = new Subject<boolean>();

  constructor(
    private cdr: ChangeDetectorRef,
    private singInBase: SignInDatabase,
    private clinicService: ClinicsService,
    private stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private location: Location
  ) {
    if (this.screenWidth <= 768) {
      this.open = false;
    }
    this.isAdmin = this.location.path(false).includes('account');
    this.isSettings = this.location.path(false).includes('settings');
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isAdmin = event.urlAfterRedirects.includes('account');
        this.isSettings = event.urlAfterRedirects.includes('settings');
        this.cdr.markForCheck();
      });
  }

  ngOnInit() {
    this.stateService.clinicLogo.subscribe(data => {
      this.clinicLogo = data;
      this.cdr.markForCheck();
    });
    this.getUserProfile();
    // this.breakpoint.observe('(max-width: 856px)')
    //   .pipe(
    //     takeUntil(this.until)
    //   )
    //   .subscribe((v) => {
    //     // this.isTablet = v.matches;
    //     // this.showDropdown = false;
    //   });
    if (!this.user?.active_clinic['verified']) this.checkUserVerified();
  }

  private checkUserVerified(): void {
    this.stateService.showSideMenuSetting.subscribe(data => {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects.includes('settings') || data) this.isSettings = true;
      });
      this.cdr.detectChanges();
    });
  }

  getUserProfile() {
    this.stateService.userProfile.pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.user = result;
      this.cdr.markForCheck();
      if (this.user && this.user.active_clinic) {
        this.clinicService.getClinicsDetails(this.user.active_clinic.id).subscribe((httpDetail: HttpWrapper<ClinicModel>) => {
          if (httpDetail && httpDetail.data && httpDetail.data.logo) {
            this.stateService.clinicLogo.next(httpDetail.data.logo.url);
          }
        });
        this.clinicService.getPersonalClinics().subscribe(response => {
          if (response.status === 'ok') {
            this.personalClinics = response.data.length > 1 ? response.data : [];
          }
        });
      }
      let isFirstTimeLOgin = this.route.snapshot.queryParams['fistTime']
      if (!this.user?.active_clinic?.is_admin && (isFirstTimeLOgin || !this.user?.active_clinic['verified'] )) {
        this.isSettings = true;
        this.showSettingOnly = true;
      }
    }, err => {
      localStorage.removeItem('Authorization');
      this.sessionService.setAuthorizationToken(null);
      this.router.navigate(['authorization', 'login']);
    });
  }

  setSettingsMenu(value) {
    this.isSettings = value;
  }


  changeOpen() {
    if (this.screenWidth > 768) {
      this.open = !this.open;
    }
  }

  setAdmin() {
    this.cdr.markForCheck();
    if (this.isAdmin) {
      if (this.isSettings) {
        this.setSettingsMenu(false);
        return;
      }
      this.router.navigate(['/calendar']);
    } else {
      this.router.navigate(['/account/orgprofile']);
    }
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public logOut() {
    this.singInBase.logOut().subscribe({
      next: res => {
        this.sessionService.setAuthorizationToken(null);
        this.sessionService.stopHeartBeating();
        this.stateService.clearUserProfile();
        this.router.navigate(['authorization', 'login']).then();
      },
      error: error => console.log(error.error.message)
    });
  }

  checkInToClinic(clinic: ClinicModel) {
    this.clinicService.changeActiveClinic(clinic.id).subscribe(response => {
      if (response.data.success) {
        this.stateService.invalidateUserProfile().subscribe(() => {
          location.reload();
        });
      }
    });
  }

  backToClinic() {
    if (this.isAdmin || this.isSettings) {
      this.router.navigate(['/calendar']);
    }
    this.isSettings = false;
    this.isAdmin = false;
  }
}
