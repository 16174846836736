<ul [class]="'pagination' + (size ? ' pagination-' + size : '')">
  <li *ngIf="boundaryLinks" class="page-item"
      [class.disabled]="!hasPrevious() || disabled">
    <a aria-label="First" class="page-link" href
       (click)="!!selectPage(1)" [attr.tabindex]="(hasPrevious() ? null : '-1')">
      <span aria-hidden="true">&laquo;&laquo;</span>
    </a>
  </li>
  <li *ngIf="directionLinks" class="page-item"
      [class.disabled]="!hasPrevious() || disabled">
    <a aria-label="Previous" class="page-link" href
       (click)="!!selectPage(page-1)" [attr.tabindex]="(hasPrevious() ? null : '-1')">
      <span aria-hidden="true">PREV</span>
    </a>
  </li>
  <li *ngFor="let pageNumber of pages" class="page-item" [class.active]="pageNumber === page"
      [class.disabled]="isEllipsis(pageNumber) || disabled">
    <a *ngIf="isEllipsis(pageNumber)" class="page-link">...</a>
    <a *ngIf="!isEllipsis(pageNumber)" class="page-link" href (click)="!!selectPage(pageNumber)">
      {{pageNumber}}
      <span *ngIf="pageNumber === page" class="sr-only">(current)</span>
    </a>
  </li>
  <li *ngIf="directionLinks" class="page-item" [class.disabled]="!hasNext() || disabled">
    <a aria-label="Next" class="page-link" href
       (click)="!!selectPage(page+1)" [attr.tabindex]="(hasNext() ? null : '-1')">
      <span aria-hidden="true">NEXT</span>
    </a>
  </li>
  <li *ngIf="boundaryLinks" class="page-item" [class.disabled]="!hasNext() || disabled">
    <a aria-label="Last" class="page-link" href
       (click)="!!selectPage(pageCount)" [attr.tabindex]="(hasNext() ? null : '-1')">
      <span aria-hidden="true">&raquo;&raquo;</span>
    </a>
  </li>
</ul>
