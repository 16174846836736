import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiConst} from '../app.constants';
import {HttpWrapper, HttpWrapperArray, HttpWrapperCollection} from '../core/models/http-wrapper';
import {
  IAddStaffToWaitingRoomParams,
  IAddStaffToWaitinRoomResponse,
  IApproveWaitingStaffParams,
  IGetWaitingRoomsByIdsParams,
  IGetWaitingStaffParams,
  IInvolvedWaitingRoom,
  IRemoveWaitingStaffParams,
  IWaitingRoomExtended,
  IWaitingStaffInfo,
  RequestStaffList,
  WaitingRoomModel,
  IWaitingRoomStaffInfo,
  IStatusRequestWaitingParams
} from './model/waiting-room.model';
import {PatientListItem} from './model/patient-list-item';
import {Observable} from 'rxjs';
import {SuccessResponseModel} from '../core/models/success-response.model';
import {UserModel} from '../core/models/user.model';
import {map} from 'rxjs/operators';
import {IdResponseModel} from '../core/models/id-response.model';
import {ScheduleModel} from '../core/models/schedule.model';
import {AbstractUserModel} from '../core/models/abstract-user.model';
import {SelectedWRSchedules} from '../core/models/event-params.model';


@Injectable({providedIn: 'root'})
export class WaitingRoomsService {

  constructor(private api: HttpClient) {
  }

  getWaitingRoomsList(params: {
    clinicId: string,
    roomId?: number,
    limit?: number,
    offset?: number,
    orderBy?: string,
    sortOrder?: '1' | '-1',
    search?: string,
    medical_specializations_ids?: number[],
    booking_only?: boolean[] | boolean
  }) {

    let httpParams = new HttpParams()
      .append('clinic_id', params.clinicId)
      .append('id', params.roomId ? params.roomId.toString(10) : '');

    if (params.limit) {
      httpParams = httpParams.append('limit', params.limit.toString(10));
    }
    if (params.offset) {
      httpParams = httpParams.append('offset', params.offset.toString(10));
    }
    if (params.orderBy) {
      httpParams = httpParams.append('order_by', params.orderBy);
    }
    if (params.medical_specializations_ids) {
      httpParams = httpParams.append('medical_specializations_ids', JSON.stringify(params.medical_specializations_ids));
    }
    if (params.hasOwnProperty('booking_only')) {
      httpParams = httpParams.append('booking_only', JSON.stringify(params.booking_only));
    }
    if (params.sortOrder) {
      httpParams = httpParams.append('sort_order', params.sortOrder);
    }
    httpParams = httpParams.append('search', params.search ? params.search : '');

    return this.api.get<HttpWrapperCollection<WaitingRoomModel>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms`, {
        params: httpParams
      });
  }

  getWaitingRoomIds(params: {
    clinicId: string,
  }) {

    let httpParams = new HttpParams()
      .append('clinic_id', params.clinicId)

    return this.api.get<HttpWrapperArray<number>>(
      `${ApiConst.baseUrl}/clinics/clinic/waiting_rooms`, {
        params: httpParams
      });
  }

  retrievePatientsList(waitingAreaId: number): Promise<PatientListItem[]> {
    return new Promise<PatientListItem[]>((resolve, reject) => {
      this.getWaitingRoomPatients(waitingAreaId).subscribe(patientsList => {
        if (patientsList.status === 'ok') {
          resolve(patientsList.data);
        } else {
          reject(patientsList);
        }
      });
    });
  }

  private getWaitingRoomPatients(roomId: number,
                                 limit: number = Number.MAX_SAFE_INTEGER,
                                 offset: number = 0): Observable<HttpWrapperArray<PatientListItem>> {
    const params = new HttpParams({
      fromObject: {
        room_id: roomId.toString(10),
        limit: limit.toString(10),
        offset: offset.toString(10)
      }
    });
    return this.api.get<HttpWrapperArray<PatientListItem>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms/participants`, {
        params
      });
  }

  getWaitingRoomById(clinicId: string, areaId: number) {
    return this.getWaitingRoomsList({
      clinicId: clinicId,
      roomId: areaId,
      offset: 0,
      limit: 1
    });
  }

  doctorCheckIn(roomId: number) {
    const params = {
      room_id: roomId
    };
    return this.api.post<HttpWrapper<{ user_id: string, waiting_room_id: number }>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms/check_in`, params);
  }

  doctorCheckOut(params) {
    const options = { headers: {}, body: params };
    return this.api.delete<HttpWrapperArray<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/check_in`, options);
  }

  getDoctorCheckedIn<WR = any>(additionalParams?: any): Observable<HttpWrapper<WR>> {
    let params = new HttpParams({ fromObject: { ...additionalParams } } );
    return this.api.get<HttpWrapper<WR>>( `${ApiConst.baseUrl}/clinics/waiting_rooms/check_in`,{params});
  }


  pickPatientToAppointment(params) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms/pick`, params);
  }

  getDoctorsList() {

    const params = {
      roles: ['doctor'],
      limit: Number.MAX_SAFE_INTEGER
    };
    return this.api.post<{ count: number, rows: UserModel[] }>(
      `${ApiConst.baseUrl}/api/v3/users`, params);
  }

  getPatientById(patientId: string, clinicId: string) {

    const params = {
      clinic_id: clinicId,
      limit: Number.MAX_SAFE_INTEGER
    };
    return this.api.post<{ count: number, rows: UserModel[] }>(
      `${ApiConst.baseUrl}/api/v3/users`, params).pipe(map(patients => {
      const foundedPatient = patients.rows.find(item => item.id === patientId);
      if (foundedPatient) {
        patients.rows = [foundedPatient];
      }
      return patients;
    }));
  }

  referPatientToWaitingRoom(roomId: number, patientId: string, targetRoomId: number) {
    const params = {
      room_id: roomId,
      target_id: patientId,
      target_room_id: targetRoomId
    };

    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/refer`, params);
  }

  checkInPatientToWaitingRoom(params) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/join`, params);
  }

  getIntoPatientToWaitingRoom(patientID: string) {
    return this.api.get<HttpWrapperArray<WaitingRoomModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/my?patient_id=${patientID}`);
  }

  releasePatient(roomId: number, patientId: string) {
    const params = {
      room_id: roomId,
      target_id: patientId
    };

    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/release`, params);
  }


  createWaitingRoom(params: {
    clinic_id: string,
    title: string,
    avatar_id?: string,
    schedules: ScheduleModel[],
    booking_only: boolean,
    medical_specializations_id: number,
    per_invitation_only: boolean,
    email: string,
    telephone: string,
    participant_doctors?: string[]
  }) {

    return this.api.post<HttpWrapper<IdResponseModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms`, params);
  }


  editWaitingRoom(params: {
    id: number,
    clinic_id: string,
    title: string,
    avatar_id?: string,
    schedules: ScheduleModel[],
    booking_only: boolean,
    medical_specializations_id: number,
    per_invitation_only: boolean,
    email: string,
    telephone: string,
    participant_doctors?: string[]
  }) {

    return this.api.put<HttpWrapper<IdResponseModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms`, params);
  }

  removeWaitingRoom(id: number) {
    const params = {
      id
    };
    const options = {
      headers: {},
      body: params
    };
    return this.api.delete<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms`, options);
  }

  getWaitingRoomDoctors(roomId: number) {
    const params = new HttpParams().append('room_id', roomId.toString(10));
    return this.api.get<HttpWrapperArray<AbstractUserModel>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms/staff`, {params});
  }

  kickDoctorFromWaitingRoom(doctorId: string) {
    const params = {
      target_id: doctorId
    };
    return this.api.post<HttpWrapperArray<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms/check_in/kick`, params);
  }

  moveDoctorToWaitingRoom(doctorId: string, roomId: number) {
    const params = {
      target_id: doctorId,
      target_room_id: roomId
    };
    return this.api.post<HttpWrapperArray<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms/check_in/move`, params);
  }

  changeAccessWaitingRoom(clinicId: string, value: boolean) {
    const params = {
      clinic_id: clinicId,
      value: value
    };
    return this.api.post<HttpWrapperArray<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms/online_consultation/status`, params);
  }

  getStatusWaitingRooms(clinicId: string) {
    return this.api.get<HttpWrapperArray<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/clinics/waiting_rooms/online_consultation/status/${clinicId}`);
  }

  public addStaffToWaitingRoom(params: IAddStaffToWaitingRoomParams): Observable<HttpWrapper<IAddStaffToWaitinRoomResponse>> {
    return this.api.post<HttpWrapper<IAddStaffToWaitinRoomResponse>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/staff/add`, params)
  }

  public getWaitingStaff(params: IGetWaitingStaffParams): Observable<HttpWrapperCollection<IWaitingStaffInfo>> {
    const httpParams = new HttpParams({ fromObject: { ...params } });
    return this.api.get<HttpWrapperCollection<IWaitingStaffInfo>>(`${ApiConst.baseUrl}/clinics/admin/waiting_staff`, { params: httpParams });
  }

  public approveWaitingStaff(params: IApproveWaitingStaffParams): Observable<HttpWrapper<{ status: boolean }>> {
    return this.api.post<HttpWrapper<{ status: boolean }>>(`${ApiConst.baseUrl}/clinics/admin/approve`, params);
  }

  public removeWaitingStaff(params: IRemoveWaitingStaffParams): Observable<HttpWrapper<{ success: boolean }>> {
    return this.api.post<HttpWrapper<{ success: boolean }>>(`${ApiConst.baseUrl}/clinics/admin/remove`, params);
  }

  public getWaitingRoomsByIds(params: IGetWaitingRoomsByIdsParams): Observable<HttpWrapperArray<IWaitingRoomExtended>> {
    return this.api.post<HttpWrapperArray<IWaitingRoomExtended>>(`${ApiConst.baseUrl}/clinics/clinic/waiting_rooms/multiple`, params);
  }

  public removeStaffFromList(params){
    return this.api.post<HttpWrapperArray<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/staff/remove`, params);
  }

  public getWaitingRoomsDoctorInvolvedIn(additionalParams: any): Observable<HttpWrapperArray<IInvolvedWaitingRoom>> {
    // const params = new HttpParams({ fromObject: { user_id }});
    let params = new HttpParams({ fromObject: { ...additionalParams } } );
    return this.api.get<HttpWrapperArray<IInvolvedWaitingRoom>>(`${ApiConst.baseUrl}/clinics/clinic/doctors/waiting_rooms`, { params });
  }

  public getRequestList(id: number, clinic_id: string): Observable<RequestStaffList[]> {
    return this.api.get<RequestStaffList[]>(`${ApiConst.baseUrl}/clinics/waiting_rooms/request?room_id=${id}&clinic_id=${clinic_id}`);
  }

  public sendRequest(params: IStatusRequestWaitingParams): Observable<HttpWrapper<{ success: boolean }>> {
    return this.api.post<HttpWrapper<{ success: boolean }>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/request`, params);
  }

  public requestAccess(params: IApproveWaitingStaffParams) {
    return this.api.put<HttpWrapper<IApproveWaitingStaffParams>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/request/change`, params);
  }

  public requestStatus(params: IStatusRequestWaitingParams) {
    return this.api.post<HttpWrapper<{status: string}>>(`${ApiConst.baseUrl}/clinics/waiting_rooms/request/check`, params);
  }

  public getResponsibleList(room_id: number, clinic_id: string) {
    return this.api.get<IWaitingRoomStaffInfo[]>(`${ApiConst.baseUrl}/clinics/admin/responsible?room_id=${room_id}&clinic_id=${clinic_id}`);
  }

  public addRemoveResponsible(params) {
    return this.api.put<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/admin/responsible/change`, params);
  }

  public getScheduleListBookingOnly(roomId) {
    return this.api.get<HttpWrapperArray<SelectedWRSchedules>>(`${ApiConst.baseUrl}/calendar/event/waiting_room/${roomId}`);
  }
  public pickPatientEvent(data) {
    return this.api.post<HttpWrapper<any>>(`${ApiConst.baseUrl}/clinics/booking_calls/pick`, data);
  }

  getBookingAppointmentParticipants(eventId: number) {
    const params = new HttpParams().append('event_id', eventId.toString(10));
    return this.api.get<HttpWrapperArray<PatientListItem>>(`${ApiConst.baseUrl}/clinics/booking_calls/participants`,{params});
  }

  releasePatientBookingAppointment(eventId: number) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/clinics/booking_calls/release`, {event_id: eventId});
  }
}
