import {IWebsocketService, IWsMessage, WebSocketConfig} from './websocket.interfaces';
import {WebSocketSubject, WebSocketSubjectConfig} from 'rxjs/webSocket';
import {BehaviorSubject, interval, Observable, Observer, Subject, SubscriptionLike} from 'rxjs';
import {distinctUntilChanged, filter, first, map, share, takeWhile} from 'rxjs/operators';

export class WebsocketConnection implements IWebsocketService {
  public readonly websocketConnectionToken: string;

  private onConnected = new BehaviorSubject<boolean>(false);

  private websocket$: WebSocketSubject<IWsMessage<any>>;
  private wsMessages$: Subject<IWsMessage<any>>;

  private websocketSub: SubscriptionLike;
  private statusSub: SubscriptionLike;
  private reconnection$: Observable<number>;
  private connection$: Observer<boolean>;
  private isConnected: boolean;
  private reconnectInterval: number;
  private reconnectAttempts: number;

  private config: WebSocketSubjectConfig<IWsMessage<any>>;

  public status: Observable<boolean>;

  constructor(config: WebSocketConfig, randomToken: string) {
    this.reconnectInterval = config.reconnectInterval || 5000; // pause between connections
    this.reconnectAttempts = config.reconnectAttempts || 10; // number of connection attempts
    this.websocketConnectionToken = randomToken;
    this.config = {
      url: config.url,
      closeObserver: {
        next: (event: CloseEvent) => {
          this.websocket$ = null;
          this.connection$.next(false);
          console.log('WebSocket closed!', event);
        }
      },
      openObserver: {
        next: (event: Event) => {
          console.log('WebSocket connected!');
          this.connection$.next(true);
        }
      }
    };

    this.wsMessages$ = new Subject<IWsMessage<any>>();
    this.websocket$ = new WebSocketSubject(config);
    // this.websocket$.subscribe(
    //   (message) => this.wsMessages$.next(message),
    //   (error: Event) => {
    //     if (!this.websocket$) {
    //       // run reconnect if errors
    //       this.reconnect();
    //     }
    //   });

    // connection status
    this.status = new Observable<boolean>((observer) => {
      this.connection$ = observer;
    }).pipe(share(), distinctUntilChanged());

    // run reconnect if not connection
    this.statusSub = this.status
      .subscribe((isConnected) => {
        this.isConnected = isConnected;

        if (!this.reconnection$ && typeof (isConnected) === 'boolean' && !isConnected) {
          // this.reconnect();
        }
      });

    this.websocketSub = this.wsMessages$.subscribe(
      null, (error: ErrorEvent) => console.error('WebSocket error!', error)
    );

  }


  connect(): WebsocketConnection {
    this.websocket$ = new WebSocketSubject(this.config);
    console.log('try to open new connections');
    this.websocket$.subscribe(
      (message) => {
        this.wsMessages$.next(message);
        if (!this.onConnected.getValue()) {
          this.onConnected.next(true);
        }
      },
      (error: Event) => {
        if (!this.websocket$) {
          // run reconnect if errors
          this.reconnect();
        }
      });
    return this;
  }

  /*
 * reconnect if not connecting or errors
 * */
  private reconnect(): void {
    this.reconnection$ = interval(this.reconnectInterval)
      .pipe(takeWhile((v, index) => index < this.reconnectAttempts && !this.websocket$));

    this.reconnection$.subscribe(
      () => this.connect(),
      () => {
      },
      () => {
        // Subject complete if reconnect attemts ending
        this.reconnection$ = null;

        if (!this.websocket$) {
          this.wsMessages$.complete();
          this.connection$.complete();
        }
      });
  }

  /*
  * on message event
  * */
  public on<T>(event: string): Observable<T> {
    if (event) {
      return this.wsMessages$.pipe(
        filter((message: IWsMessage<T>) => {
          return message.requested_action ? (message.requested_action === event) : (message.broadcast_message_type === event);
        }),
        map((message: IWsMessage<T>) => message.data)
      );
    }
  }

  /*
  * on message to server
  * */
  public send(event: string, data: any = {}): void {
    if (event) {
      this.onConnected
        .pipe(filter(item => !!(item)), first())
        .subscribe(isConnected => {
          this.websocket$.next(<any>{event_type: event, payload: data});
        });

    } else {
      console.error('Send error!');
    }
  }

  public sendToken(data: any = {}): void {
    if (data) {
      this.websocket$.next(<any>data);
    } else {
      console.error('Auth error!');
    }
  }


  private closeConnection() {
    this.onConnected.next(false);
    this.websocket$ = null;
    this.connection$.next(false);
    console.log('WebSocket closed!');
  }

  private dispose() {
    this.websocketSub.unsubscribe();
    this.statusSub.unsubscribe();
  }
}

