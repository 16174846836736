<div #container class="notification-system-container" (click)="click.emit($event)">
  <div class="notification-header">
    <div>
      <span class="notification-icon" inlineSVG="assets/svg/ic_chat.svg"></span>
      <span class="notification-title">{{notification.title}}</span>
    </div>
    <button class="close-button" mat-icon-button (click)="close.emit(notification.id)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="notification-content">
    {{notification.message}}
  </div>
  <div class="button-container">
    <button mat-button (click)="$event.stopPropagation(); markAsReadClick()">{{'chat.markRead' | translate}}</button>
  </div>
</div>
