import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {StateService} from './core/providers/state.service';
import {catchError, switchMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  constructor(
    private router: Router,
    private stateService: StateService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!localStorage.getItem('Authorization')) {
      return this.router.createUrlTree(['authorization', 'login']);
    }
    return this.stateService.userProfile.pipe(
      switchMap(userProfile => {
        if (
          !userProfile.dob ||
          !userProfile.photo ||
          !userProfile.gender ||
          !userProfile.active_clinic
        ) {
          return route.url.length > 0 && route.url[0].path === 'clinic-details' ?
            of(true) :
            of(this.router.createUrlTree(['authorization', 'register', 'clinic-details']));
        } else if (!state.url || state.url === '/') {
          if (userProfile.active_clinic.is_admin) {
            return of(this.router.createUrlTree(['account/onboarding']));
          } else {
            return of(this.router.createUrlTree(['calendar']));
          }
        } else {
          return of(true);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return of(this.router.createUrlTree(['authorization', 'login']));
        } else {
          return throwError(() => {
            return error;
          });
        }
      })
    );
  }
}

