import {ApiConst} from '../../../app.constants';
import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {HttpWrapper, HttpWrapperCollection} from '../../../core/models/http-wrapper';
import {UserModel} from '../../../core/models/user.model';
import {tap} from 'rxjs/operators';
import {SecurityQuestionModel} from '../../../core/models/security-question.model';
import {SuccessResponseModel} from '../../../core/models/success-response.model';


@Injectable({providedIn: 'root'})
export class SignInDatabase {

  userLoggedIn$ = new EventEmitter<UserModel>();

  constructor(
    private api: HttpClient,
  ) {
  }


  public logIn(params) {

    return this.api.post<HttpWrapper<{
      access: string,
      profile: UserModel,
      refresh: string
    }>>(`${ApiConst.baseUrl}/auth/login`, params).pipe(tap(response => {
      this.userLoggedIn$.emit(response.data.profile);
    }));
  }

  public logOut() {
    return this.api.post(`${ApiConst.baseUrl}/logout`, {}).pipe(tap(() => {
      localStorage.removeItem('Authorization');
    }, error => {
      localStorage.removeItem('Authorization');
    }));
  }

  public getLogIn() {
    return (localStorage.getItem('Authorization') !== null);
  }

  public getUserProfile() {
    return this.api.get<HttpWrapper<UserModel>>(`${ApiConst.baseUrl}/api/v3/profile`);
  }

  public updateUserProfile(params) {
    return this.api.patch(`${ApiConst.baseUrl}/api/v3/profile`, params);
  }

  public changePass(pass: string) {
    const options = {password: pass};
    return this.api.patch(`${ApiConst.baseUrl}/api/v3/profile/password`, options);
  }

  public sendConfirmCode(params:{
    where_to_send: string
  }) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/cloud/users/confirmation`, params);
  }
  public verifyConfirmCode(params) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/cloud/users/confirmation/verify_code`, params);
  }

  public recoveryQuestion(questionId: number, answer: string) {
    const options = {questionId: questionId, answer: answer};
    return this.api.post(`${ApiConst.baseUrl}/api/v3/profile/recovery_question`, options);
  }

  public getRecoveryQuestions() {
    return this.api.get<HttpWrapperCollection<SecurityQuestionModel>>(`${ApiConst.baseUrl}/api/v3/recovery-questions?pageSize=${Number.MAX_SAFE_INTEGER}`);
  }

  public getUserRecoveryQuestion() {
    return this.api.get<HttpWrapper<SecurityQuestionModel>>(`${ApiConst.baseUrl}/api/v3/profile/recovery_question`);
  }

  public restorePin(params: {
    email?: string,
    telephone?: string,
    qId: number,
    answer: string,
    role: string
  }) {
    return this.api.post<SuccessResponseModel>(`${ApiConst.baseUrl}/restore/credentials`, params);
  }
}
