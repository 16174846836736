import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import Amplify, {Auth} from 'aws-amplify';
import {AWSIoTProvider} from '@aws-amplify/pubsub';
import {ApiConst} from './app/app.constants';

if (environment.production) {
  enableProdMode();
}



Amplify.configure(ApiConst.awsConfig);
Auth.configure(ApiConst.awsConfig);

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: ApiConst.awsRegion,
  aws_pubsub_endpoint: `wss://${ApiConst.awsMqttId}.iot.${ApiConst.awsRegion}.amazonaws.com/mqtt`,
}));
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));


