<div class="sidebar-container" [ngClass]="{'open': open}">
  <button mat-fab class="sidebar-close-button" (click)="changeOpen()" *ngIf="screenWidth > 768">
    <mat-icon *ngIf="open">keyboard_arrow_left</mat-icon>
    <mat-icon *ngIf="!open">keyboard_arrow_right</mat-icon>
  </button>
  <div class="menu-bar-container" *ngIf="!isSettings">
    <img *ngIf="clinicLogo" class="clinic-logo" [src]="clinicLogo" alt="" [ngClass]="{'open': open}">
    <div *ngIf="!clinicLogo" class="clinic-no-logo" [ngClass]="{'open': open}">
      <span *ngIf="open">{{user?.active_clinic?.name}}</span>
    </div>
    <div class="menu-container" [ngClass]="{'d-none': isAdmin}">
      <button mat-button class="menu-button" routerLink="/calendar" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_calendar.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.calendar' | translate}}</span>
      </button>
      <button routerLink="/patients" routerLinkActive="active" mat-button class="menu-button">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_patients.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.patients' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/treatments" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_treatments.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.treatments' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/monitoring" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_monitoring.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.monitoring' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/media" routerLinkActive="active">
        <span class="menu-icon heart-icon" inlineSVG="assets/svg/sidemenu/ic_media.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.media' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/chat" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_chat.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.chatCalls' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/waiting-rooms" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_consultations.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.consultations' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/notifications" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-notifications.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.notifications' | translate}}</span>
      </button>
    </div>
    <div class="menu-container" [ngClass]="{'d-none': !isAdmin}">
      <button mat-button class="menu-button" routerLink="/account/orgprofile" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-organization.svg"></span>
        <span *ngIf="open" class="menu-item-label">Clinic profile</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/departments" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-departments.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.admin.departments' | translate}}</span>
      </button>
      <button routerLink="/account/users" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" mat-button
              class="menu-button">
        <span class="menu-icon" inlineSVG="assets/svg/ic-clinics.svg"></span>
        <span *ngIf="open" class="menu-item-label">Colleagues</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/patients" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-users.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.admin.patients' | translate}}</span>
      </button>

      <button mat-button class="menu-button" routerLink="/account/automatic-booking" routerLinkActive="active">
<!--      <button mat-button class="menu-button">-->
        <span class="menu-icon" inlineSVG="assets/svg/onboarding-dashboard/ic-automatic-bookings.svg"></span>
        <span *ngIf="open" class="menu-item-label">Automatic bookings</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/waiting-rooms" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_consultations.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.admin.waitingRooms' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/online-consultation" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_waiting rooms.svg"></span>
        <span *ngIf="open" class="menu-item-label">Online Consultations</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/chat-calls" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_chat.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.chatCalls' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/ehr" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/ic-ehr.svg"></span>
        <span *ngIf="open" class="menu-item-label">EHR</span>
      </button>
<!--      <button mat-button class="menu-button" routerLinkActive="active">-->
<!--        <span class="menu-icon" inlineSVG="assets/svg/ic-treatments.svg"></span>-->
<!--        <span *ngIf="open" class="menu-item-label">Online treatments</span>-->
<!--      </button>-->
      <button mat-button class="menu-button" routerLink="/account/monitoring" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic_monitoring.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.monitoring' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/connected-apps" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-connected-apps.svg"></span>
        <span *ngIf="open" class="menu-item-label">Connected apps</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/payments" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-payments.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.admin.payments' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-api.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.admin.API' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLink="/account/license" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-licence.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.admin.license' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-about-appotek.svg"></span>
        <span *ngIf="open" class="menu-item-label">About Appotek</span>
      </button>
    </div>
  </div>
  <div class="menu-bar-container menu-bar-container_bottom" *ngIf="isSettings">
    <div class="menu-container">
      <div class="menu-settings-title">
        {{'sidebar.settings.settings' | translate}}
      </div>
      <!--      <button mat-button class="menu-button-settings" (click)="setSettingsMenu(false)" routerLinkActive="active">-->
      <!--        <span class="menu-icon" inlineSVG="assets/svg/arrow-down.svg"></span>-->
      <!--        <span *ngIf="open" class="menu-item-label">Settings</span>-->
      <!--      </button>-->
      <button mat-button class="menu-button" routerLinkActive="active" [routerLink]="['settings', 'profile']">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-profile.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.profile' | translate}}</span>
      </button>
      <button mat-button class="menu-button" [routerLink]="['settings', 'reviews']" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-reviews.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.reviews' | translate}}</span>
      </button>
      <button mat-button class="menu-button" [routerLink]="['settings', 'license']" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-license.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.admin.license' | translate}}</span>
      </button>
      <button mat-button class="menu-button" [routerLink]="['settings', 'earnings']" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-earnings.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.earnings' | translate}}</span>
      </button>
      <button mat-button class="menu-button" [routerLink]="['settings', 'notifications']" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-notifications.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.notifications' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-themes.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.themes' | translate}}</span>
      </button>
      <button mat-button class="menu-button" [routerLink]="['settings', 'security']" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-security.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.security' | translate}}</span>
      </button>
      <button mat-button class="menu-button" [routerLink]="['settings', 'app-service']" routerLinkActive="active">
        <span class="menu-icon heart-icon" inlineSVG="assets/svg/sidemenu/ic-app-service.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.appService' | translate}}</span>
      </button>
      <button mat-button class="menu-button" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-tutorial.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.tutorial' | translate}}</span>
      </button>
      <button mat-button class="menu-button" [routerLink]="['settings', 'about-us']" routerLinkActive="active">
        <span class="menu-icon" inlineSVG="assets/svg/sidemenu/ic-about.svg"></span>
        <span *ngIf="open" class="menu-item-label">{{'sidebar.settings.about' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="user-container">
    <button mat-button [ngClass]="{'open': open}">
      <img class="user-photo" [src]="user?.photo || './../../../../assets/img/default-user.png'" alt="">
      <span class="user-name" *ngIf="open">
        <span>{{user?.firstName}} {{user?.lastName}}</span>
      </span>
      <mat-select #userMenu [panelClass]="'mat-select-bottom-panel-class'">

        <mat-option (click)="backToClinic()" *ngIf="(isAdmin ||isSettings) && !showSettingOnly">
          <div class="clinic-settings">
            <span class="settings-title">
              <mat-icon>arrow_back</mat-icon>
              {{'sidebar.settings.backToClinic' | translate}}
            </span>
          </div>
        </mat-option>
        <mat-option (click)="setSettingsMenu(true)" *ngIf="!isSettings">
          <div class="clinic-settings">
            <span class="settings-title">{{'sidebar.settings.mySettings' | translate}}</span>
          </div>
        </mat-option>
        <mat-option (click)="setAdmin()" *ngIf="user?.active_clinic?.is_admin && (!isAdmin || (isAdmin && isSettings))">
          <div class="clinic-settings">
            <span class="settings-title">{{'sidebar.settings.clinicSettings' | translate}}</span>
          </div>
        </mat-option>
        <span class="switch-clinic"
              *ngIf="personalClinics.length > 0">{{'sidebar.settings.switchAccountTo' | translate}}</span>
        <div class="clinics-list-container custom-scrollbar">
          <mat-option (click)="checkInToClinic(userClinic.clinic)" *ngFor="let userClinic of personalClinics">
            <div class="clinic-settings">
              <div class="clinic-settings-detail">
                <img [src]="userClinic.clinic?.logo?.url || 'assets/svg/ic_user_photo_empty.svg'" alt="">
                <span
                  [ngClass]="{'bold': userClinic.clinic.id === user?.active_clinic?.id}">{{userClinic.clinic.name}}</span>
              </div>
            </div>
          </mat-option>
        </div>
        <mat-option (click)="logOut()">{{'sidebar.settings.logOut' | translate}}
          <span class="logout-icon" inlineSVG="assets/svg/ic_logout.svg"></span>
        </mat-option>
      </mat-select>
    </button>
  </div>
  <!--  <div *ngIf="isAdmin" class="user-container">-->
  <!--    <button mat-button [ngClass]="{'open': open}">-->
  <!--      <img class="user-photo" [src]="clinicLogo || './../../../../assets/img/default-user.png'" alt="">-->
  <!--      <span class="user-name" *ngIf="open">-->
  <!--        <span>{{user?.active_clinic?.name}}</span>-->
  <!--      </span>-->
  <!--      <mat-select [panelClass]="'mat-select-bottom-panel-class'">-->
  <!--        <mat-option (click)="setAdmin()">-->
  <!--          <div class="clinic-settings">-->
  <!--            <span class="switch-clinic">Switch account to</span>-->
  <!--            <div class="clinic-settings-detail">-->
  <!--              <img [src]="user?.photo || './../../../../assets/img/default-user.png'" alt="">-->
  <!--              <span>{{user|userFullName}}</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </mat-option>-->
  <!--        <mat-option (click)="logOut()">Log Out <span class="logout-icon" inlineSVG="assets/svg/ic_logout.svg"></span>-->
  <!--        </mat-option>-->
  <!--      </mat-select>-->
  <!--    </button>-->
  <!--  </div>-->
</div>
