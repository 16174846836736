<atk-notifications-container></atk-notifications-container>
<div class="left-orientation">
  <atk-sidebar *ngIf="showMenu"></atk-sidebar>
  <div class="cont">
    <router-outlet>
      <div *ngIf="user && user?.active_clinic && token">
        <ng-container *ngIf="!user?.active_clinic?.clinic_status?.verified; else checkApprove">
          <div class="clinic-not-verified">
            <span class="line-not-verified">Your clinic ID has not yet been authorized. Your status is: Pending. <br>
              Before onboarding patients we need to approve your clinic’s identity. </span>
            <span *ngIf="user?.active_clinic?.is_admin">
              See requirements <span class="chat-with-us tooltip-position"
                                     (click)="showDocumentRequirements = !showDocumentRequirements">here
              <div class="document-requirements" *ngIf="showDocumentRequirements">
                <div>
                  To prove your personal identity, the primary form of identification must bear a photograph and signature.
                  <br>
                  You will need to upload a picture of you holding this document you want to upload in your hand next to your face, so both your face is
                  <br>
                  visible, as well as the document. You can use one of the following documents:
                </div>
                <ul>
                  <li>A valid passport that has the Machine readable zone</li>
                  <li>A valid photo card driving licence</li>
                  <li>A valid photo card national identity card that has the Machine readable zone</li>
                </ul>
                <div>
                  To prove the business is registered on this address with this name, please upload a separate document from one of the following:
                </div>
                <ul>
                  <li>A bank credit card or bank statement (dated within the last three months and not printed from the internet)</li>
                  <li>A mortgage statement (dated within the last 12 months and not printed from the internet)</li>
                  <li>A tax bill (for the current year)</li>
                  <li>
                    A letter/bill from utility company (less than 3 months old, except for water bills, which must relate to the current charging period).<br>
                    The letter you receive must confirm you receive a service from the company and cannot be printed from the internet.
                  </li>
                </ul>
              </div>
            </span> or <span class="chat-with-us" routerLink="/settings/support">chat</span> with us.
            </span>
          </div>
        </ng-container>
        <ng-template #checkApprove>
          <div class="clinic-not-verified" *ngIf="!user?.active_clinic?.verified">
            <span>You are not approved by the clinic!</span>
          </div>
          <div class="clinic-not-verified" *ngIf="user?.active_clinic?.suspended">
            <span>You are suspended by the clinic!</span>
          </div>
          <div class="clinic-not-verified" *ngIf="!user?.active_clinic?.clinic_status?.exist_subscription">
            <span>You haven't active subscription.</span>
          </div>
        </ng-template>
      </div>
    </router-outlet>
  </div>
</div>

<swal #session>
  <ng-container *swalPortal="swalTargets.content">
    {{'global.session' | translate}} {{sessionTimerText}}
  </ng-container>
</swal>

<ng-container *ngIf="showProgressBar$ | async">
  <mat-progress-bar mode="indeterminate" class="main-progress-bar"></mat-progress-bar>
</ng-container>
