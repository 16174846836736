import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private readonly progressBar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public getProgressBarState(): Observable<boolean> {
    return this.progressBar$.asObservable();
  }

  public setProgressBar(): void {
    this.progressBar$.next(true);
  }

  public removeProgressBar(): void {
    this.progressBar$.next(false);
  }
}
