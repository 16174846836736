import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {StateService} from '../providers/state.service';
import {catchError, switchMap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClinicGuard implements CanActivate {

  constructor(
    private router: Router,
    private stateService: StateService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.stateService.userProfile.pipe(
      switchMap(userProfile => {
        if (!userProfile.active_clinic.clinic_status.verified || userProfile.active_clinic.suspended) {
          return of(this.router.createUrlTree(['settings', 'profile']));
        } else {
          return of(true);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return of(this.router.createUrlTree(['authorization', 'login']));
        } else {
          return throwError(() => {
            return error;
          });
        }
      })
    );
  }
}

