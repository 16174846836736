import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {WebSocketConfig} from './websocket.interfaces';
import {config} from './websocket.config';
import {WebsocketConnection} from './websocket-connection';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private config: WebSocketConfig = {
    url: '',
    reconnectInterval: 5000,
    reconnectAttempts: 10
  };

  private connections: { token: string, connection: WebsocketConnection }[] = [];

  constructor() {
  }


  /*
  * connect to WebSocket
  * */
  public connect(url?: string): WebsocketConnection {

    console.log(url);
    let randomString = WebsocketService.generateUniqueToken();
    while (this.connections.some(item => item.token === randomString)) {
      randomString = WebsocketService.generateUniqueToken();
    }

    const connection = new WebsocketConnection(Object.assign(this.config, {
      url: url
    }), randomString).connect();

    this.connections.push({
      token: randomString,
      connection: connection
    })
    return connection;
  }

  getConnection(connectionToken: string): WebsocketConnection {
    const foundedConnection = this.connections.find(item => item.token === connectionToken);
    console.log(foundedConnection);
    return !!(foundedConnection) ? foundedConnection.connection : null
  }

  disconnect(connection: WebsocketConnection) {
    if (!connection) {
      return;
    }
    const connectionIndex = this.connections.findIndex(item => item.token === connection.websocketConnectionToken);
    if (connectionIndex >= 0) {
      this.connections[connectionIndex].connection['closeConnection']();
      this.connections[connectionIndex].connection['dispose']();
      this.connections.splice(connectionIndex, 1);
    }
  }

  private static generateUniqueToken() {
    return Math.random().toString(36).substr(2, 9);
  }
}
