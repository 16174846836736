import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {OnboardingDashboardItem} from '../../account/onboarding-dashboard/onboarding-dashboard-items';
import {ColleagueVM} from '../../account/users/users-list/users-list.component';
import {ApiConst} from '../../app.constants';
import {InviteColleagueModel} from '../../ui/invite-colleague/invite-colleague.model';
import {ProfileEditModel} from '../../ui/profile-edit/profile-edit.model';
import {CloudIdModel} from '../models/cloud-id.model';
import {CloudProfileModel} from '../models/cloud-profile.model';
import {HttpWrapper, HttpWrapperArray, HttpWrapperCollection} from '../models/http-wrapper';
import {SuccessResponseModel} from '../models/success-response.model';
import {ISharedInfo, UserModel} from '../models/user.model';
import {UserNotificationModel} from '../models/user-notification.model';
import Amplify, {PubSub} from 'aws-amplify';
import {EcgDataModel} from '../models/ecg-data.model';
import {ZenObservable} from 'zen-observable-ts/lib/types';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userNotificationsChannel: Subject<UserNotificationModel> = new Subject<UserNotificationModel>();
  private userNotificationsChannelSubscription: ZenObservable.Subscription;

  constructor(private api: HttpClient) {
  }

  getUsers(params?): Observable<HttpWrapperArray<UserModel>> {
    const httpParams = new HttpParams({fromObject: {...params}});
    return this.api.get<HttpWrapperArray<UserModel>>(`${ApiConst.baseUrl}/cloud/users`, {params: httpParams});
  }

  getUsersMinified(ids: string[] = [], updatedAfter: string = '') {
    const params = {
      ids: ids
    };

    if (updatedAfter) {
      params['updated_after'] = updatedAfter;
    }
    return this.api.post<HttpWrapperArray<UserModel>>(
      `${ApiConst.baseUrl}/cloud/users/minified`, params);
  }

  getUserById(userId: string, clinicId?: string) {
    const params = {};
    if (clinicId) {
      params['clinic_id'] = clinicId;
    }
    return this.api.get<HttpWrapper<CloudProfileModel>>(`${ApiConst.baseUrl}/cloud/users/${userId}`, {
      params
    });
  }

  updateUserProfile(params: ProfileEditModel) {

    return this.api.put(`${ApiConst.baseUrl}/cloud/users`, params);
  }

  // updateUserProfileLegacy(params: {
  //   firstName?: string,
  //   lastName?: string,
  //   gender?: string,
  //   address?: {
  //     postal: string,
  //     city: string,
  //     street: string,
  //     buildingNumber: string,
  //     country: string,
  //   }
  //   email?: string,
  //   dob?: string,
  // }) {

  updateUsersPersonalProfile(params: ProfileEditModel) {
    return this.api.put(`${ApiConst.baseUrl}/cloud/users`, params);
  }

  public upsertDoctorId(params: {
    medical_specialization_id: number
    medical_level_id: number
    doctor_id?: string
  }) {

    return this.api.post(`${ApiConst.baseUrl}/cloud/licenses/upsert-doctor-id`, params);
  }

  getCloudIdByUserId(userId: string) {

    return this.api.get<HttpWrapper<CloudIdModel>>(
      `${ApiConst.baseUrl}/cloud/id/${userId}`);
  }

  setCloudId(params: {
    user_id: string,
    value: string,
    type_id: number
  }) {

    return this.api.post<HttpWrapper<any>>(
      `${ApiConst.baseUrl}/cloud/id`, params);
  }

  removeCloudId(params: {
    user_id: string
  }) {
    return this.api.delete<HttpWrapper<any>>(`${ApiConst.baseUrl}/cloud/id/${params.user_id}`);
  }

  getContacts(params: {
    roles?: string[]
    state: [{
      in_list?: string[]
      waiting_for?: string[]
    }]
  }) {

    return this.api.post<HttpWrapperArray<UserModel>>(
      `${ApiConst.baseUrl}/cloud/contacts`, params);
  }

  approveContactRequest(params: {
    contact_id: string
  }) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/cloud/contacts/requests/approve`, params);
  }

  rejectContactRequest(params: {
    contact_id: string
  }) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(
      `${ApiConst.baseUrl}/cloud/contacts/requests/reject`, params);
  }

  setUserAvatar(file: File): Observable<UserModel> {

    const data = new FormData();
    data.append('file', file);
    return this.api.post<UserModel>(`${ApiConst.baseUrl}/api/v3/profile/avatar`, data);
  }

  public getSharedInfo(userId: string): Observable<HttpWrapper<ISharedInfo>> {
    const params = new HttpParams({fromObject: {userId}});
    return this.api.get<HttpWrapper<ISharedInfo>>(`${ApiConst.baseUrl}/cloud/doctors/info`, {params});
  }

  public editSharedInfo(params: ISharedInfo): Observable<HttpWrapper<ISharedInfo>> {
    return this.api.post<HttpWrapper<ISharedInfo>>(`${ApiConst.baseUrl}/cloud/doctors/info`, params);
  }

  getColleagueList(param) {
    return this.api.post<HttpWrapperCollection<ColleagueVM>>(`${ApiConst.baseUrl}/cloud/doctors/merged`, param)
  }

  verifyColleague(param) {
    return this.api.post<HttpWrapperCollection<any>>(`${ApiConst.baseUrl}/clinics/verify/member`, param)
  }

  readColleague(param) {
    return this.api.post<HttpWrapperCollection<any>>(`${ApiConst.baseUrl}/clinics/verify/member/mark`, param)
  }

  getUserOnboardingTasks() {
    return this.api.get<HttpWrapperArray<OnboardingDashboardItem>>(`${ApiConst.baseUrl}/cloud/task`);
  }

  setOnboardingTaskStatus(params: {
    sign_up_task_id: number,
    is_read: boolean,
    is_done: boolean
  }) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/cloud/task`, params);
  }

  inviteColleague(params) {
    return this.api.post<HttpWrapper<InviteColleagueModel>>(`${ApiConst.baseUrl}/cloud/invites/doctor/single`, params);
  }

  getColleagueById(id) {
    const params = {
      id: id
    };
    return this.api.get<HttpWrapper<InviteColleagueModel>>(`${ApiConst.baseUrl}/cloud/invites/doctor/single`, {params});
  }


  updateColleague(params: InviteColleagueModel) {
    return this.api.put(`${ApiConst.baseUrl}/cloud/invites/doctor/single`, params);
  }

  removeColleague(params) {
    const options = {
      headers: {},
      body: params
    }
    return this.api.delete<HttpWrapper<any>>(`${ApiConst.baseUrl}/cloud/invites/doctor/single`, options);
  }

  deleteColleague(params) {
    const options = {
      headers: {},
      body: params
    }
    return this.api.delete<HttpWrapper<any>>(`${ApiConst.baseUrl}/cloud/clinic/colleague`, options);
  }

  suspendColleague(params) {
    return this.api.post<HttpWrapper<InviteColleagueModel>>(`${ApiConst.baseUrl}/clinics/professionals/suspend`, params);
  }

  resendColleague(params) {
    return this.api.post<HttpWrapper<SuccessResponseModel>>(`${ApiConst.baseUrl}/cloud/invites/patient/resend`, params);
  }

  updateActiveColleague(params: InviteColleagueModel) {
    return this.api.put(`${ApiConst.baseUrl}/cloud/users`, params);
  }

  subscribeToUserNotificationsChannel(userId: string) {
    if (!this.userNotificationsChannelSubscription || this.userNotificationsChannelSubscription.closed) {
      this.userNotificationsChannelSubscription = Amplify.PubSub.subscribe(`appotek/${ApiConst.awsPubSubEnv}/patient/${userId}`).subscribe((data) => {
        this.userNotificationsChannel.next(data.value as UserNotificationModel);
      });
    }

    return this.userNotificationsChannel;
  }

  unsubscribeFromUserNotificationsChannel(userId: string) {
    if (this.userNotificationsChannelSubscription && !this.userNotificationsChannelSubscription.closed) {
      this.userNotificationsChannelSubscription.unsubscribe();
    }
  }
}
