import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PreloadingStrategy} from '@angular/router';
import {RouterStrategy} from './helpers/router.strategy';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {AgmCoreModule} from '@agm/core';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire';
import {AsyncPipe} from '@angular/common';
import Swal from 'sweetalert2';
import {SidebarModule} from './ui/sidebar/sidebar.module';
import {NotificationsModule} from './core/notifications/notifications.module';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {AppHttpInterceptor} from './app-http.interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ApiConst} from './app.constants';

import {GoogleApiModule, NG_GAPI_CONFIG} from "ng-gapi";
import {gapiClientConfig} from './core/integrations/google/configs/google-auth.config';

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalModule,
  MsalService
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './core/integrations/outlook/configs/outlook-auth.config';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {AmplifyAngularModule} from 'aws-amplify-angular';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: ApiConst.API_KEY,
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule,
    SidebarModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyANJbG3u8gcVv6JBmuihYsru3RVEDdMdlM',
      authDomain: 'appotek-7ac89.firebaseapp.com',
      databaseURL: 'https://appotek-7ac89.firebaseio.com',
      projectId: 'appotek-7ac89',
      storageBucket: 'appotek-7ac89.appspot.com',
      messagingSenderId: '57597666984',
      appId: '1:57597666984:web:cd1e1d8ad727cad51e849d'
    }),
    SweetAlert2Module.forRoot({
      provideSwal: Swal.mixin({

        heightAuto: false,
        showClass: {
          popup: 'animate__animated zoomInSmall fastest'
        },
        hideClass: {
          popup: 'animate__animated animate__zoomOut fastest'
        },
        reverseButtons: true,
        customClass: {
          confirmButton: 'confirm-popup-button',
          cancelButton: 'cancel-popup-button',
          title: 'popup-title',
          content: 'popup-text'
        }
      })
    }),
    NotificationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // NgWebPushModule.forRoot({
    //   apiKey: 'AIzaSyANJbG3u8gcVv6JBmuihYsru3RVEDdMdlM',
    //   authDomain: 'appotek-7ac89.firebaseapp.com',
    //   databaseURL: 'https://appotek-7ac89.firebaseio.com',
    //   projectId: 'appotek-7ac89',
    //   storageBucket: 'appotek-7ac89.appspot.com',
    //   messagingSenderId: '57597666984',
    //   appId: '1:57597666984:web:cd1e1d8ad727cad51e849d'
    // })
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    MatProgressBarModule,
    MsalModule,
    AmplifyAngularModule
  ],
  providers: [
    AsyncPipe,
    {provide: PreloadingStrategy, useClass: RouterStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true},
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
