import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {interval} from 'rxjs';
import {NotificationMessageModel} from '../models/notifications/notification-message.model';
import {ChatAndCallWebsocketService} from '../../../chat/chat-and-call-websocket.service';

@Component({
  selector: 'atk-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})

export class NotificationMessageComponent implements OnInit {

  @ViewChild('container', {static: true}) containerElement: ElementRef<HTMLDivElement>;

  @Input() notification: NotificationMessageModel;
  @Output() close = new EventEmitter<number>();
  @Output() markAsRead = new EventEmitter<number>();
  @Output() click = new EventEmitter<MouseEvent>()
  opacity = 0;

  constructor(
    private chatWebsocketService: ChatAndCallWebsocketService
  ) {
  }

  ngOnInit() {
    this.chatWebsocketService.connect();
    if (this.notification.timeout) {
      const timeoutSubscription = interval(this.notification.timeout).subscribe(() => {
        timeoutSubscription.unsubscribe();
        this.close.emit(this.notification.id);

      })
    }
  }


  markAsReadClick() {
    console.log(this.notification);
    this.chatWebsocketService.readMessage({
      room_id: this.notification.roomId,
      message_id: this.notification.messageId
    })
    this.markAsRead.emit(this.notification.id);
  }
}
