import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ElectronicHealthRecordModel} from '../../electronic-health-record/electronic-health-record.model';
import {UserModel} from '../models/user.model';
import {SignInDatabase} from '../../authorization/sign-in/datasource/sign-in.database';
import {map, switchMap} from 'rxjs/operators';
import {WaitingRoomModel} from '../../waiting-area/model/waiting-room.model';
import {WaitingRoomsService} from '../../waiting-area/waiting-rooms.service';
import {BasicReportCreationModel, ConsultationReportCreationModel} from '../models/consultation-report-creation.model';
import {ClinicsService} from './clinics.service';
import {CalendarFilters} from '../models/calendar-filters.model';
import {TrackersFilters} from '../models/trackers-filter.model';
import {MedicalSpecializationModel} from '../models/medical-specialization.model';
import {Moment} from "moment";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private micDevice = new BehaviorSubject<string>(null);
  private soundDevice = new BehaviorSubject<string>(null);
  public clinicLogo = new BehaviorSubject<string>(null);
  public currentView = new BehaviorSubject<string>('list');
  public showSideMenuSetting = new BehaviorSubject<boolean>(false);


  private selectedConsultationReport = new BehaviorSubject<ElectronicHealthRecordModel>(null);

  public filterCalendar = new BehaviorSubject<CalendarFilters>({
    COMMON: true,
    DAY_OFF: true,
    CONSULTATION: true,
    HOME_VISIT: true,
    CALL: true
  });

  public filterTrackers = new BehaviorSubject<TrackersFilters>({
    heart_rate: true,
    oxygen_in_blood: true,
    blood_pressure: true,
    sleep: true,
    breathe: true
  });


  private consultationReportCreationData = new BehaviorSubject<ConsultationReportCreationModel>(null);
  private readonly basicReportCreationData = new BehaviorSubject<BasicReportCreationModel>(null);

  videoSettings;
  electronicHealthRecord;

  private _userProfile = new BehaviorSubject<UserModel>(null);
  private _medicalSpecializations = new BehaviorSubject<MedicalSpecializationModel[]>(null);

  waitingRooms: WaitingRoomModel[] = [];

  private selectedPatient: BehaviorSubject<UserModel> = new BehaviorSubject(null);
  private selectedDateState: BehaviorSubject<Moment> = new BehaviorSubject(null);

  constructor(
    private signInDatabase: SignInDatabase,
    private waitingAreaService: WaitingRoomsService,
    private clinicsService: ClinicsService,
  ) {


    this.videoSettings = {
      micDevice$: this.micDevice.asObservable(),
      soundDevice$: this.micDevice.asObservable(),
      setMicDevice: (micDevice: string) => {
        this.micDevice.next(micDevice);
      },
      setSoundDevice: (soundDevice: string) => {
        this.soundDevice.next(soundDevice);
      }
    };

    this.electronicHealthRecord = {
      selectedConsultationReport$: this.selectedConsultationReport.asObservable(),
      setSelectedConsultationReport: (selectedRecord: ElectronicHealthRecordModel) => {
        this.selectedConsultationReport.next(selectedRecord);
      },

      consultationReportCreationData$: this.consultationReportCreationData.asObservable(),
      setConsultationReportCreationData: (data: ConsultationReportCreationModel) => {
        this.consultationReportCreationData.next(data);
      },

      basicReportCreationData$: this.basicReportCreationData.asObservable(),
      setBasicReportCreationData: (data: BasicReportCreationModel) => {
        this.basicReportCreationData.next(data);
      }
    };


  }

  clearUserProfile() {
    this._userProfile.next(null);
  }

  get userProfile() {
    const userProfile = this._userProfile.getValue();

    if (!userProfile) {
      return this.invalidateUserProfile().pipe(
        switchMap(() => this._userProfile)
      );
    } else {
      return this._userProfile;
    }
  }

  invalidateUserProfile() {
    return this.signInDatabase.getUserProfile().pipe(
      map(item => {
        this._userProfile.next(item.data);
      }),
    );
  }

  public setUserProfile(state: UserModel): void {
    this._userProfile.next(state);
  }


  get medicalSpecializations() {
    const medicalSpecializations = this._medicalSpecializations.getValue();

    if (!medicalSpecializations) {

      return this.clinicsService.getClinicsSpecializations().pipe(
        map(item => this._medicalSpecializations.next(item.data)),
        switchMap(() => this._medicalSpecializations.asObservable())
      );
    } else {
      return this._medicalSpecializations.asObservable();
    }
  }

  // TODO: Remove as soon as possible

  preloadWaitingRooms() {
    this.userProfile.subscribe(userProfile => {
      if (userProfile && userProfile.active_clinic) {
        this.waitingAreaService.getWaitingRoomsList({
          clinicId: userProfile.active_clinic.id
        }).subscribe(response => {
          if (response.status === 'ok') {
            this.waitingRooms = response.data.rows;
          }
        });
      }
    });
  }

  public getSelectedPatient(): Observable<UserModel> {
    return this.selectedPatient.asObservable();
  }

  public setSelectedPatient(patient: UserModel): void {
    return this.selectedPatient.next(patient);
  }

  public getSelectedDateState(): Observable<Moment> {
    return this.selectedDateState.asObservable();
  }

  public setSelectedDateState(nextState: Moment): void {
    return this.selectedDateState.next(nextState);
  }
}
