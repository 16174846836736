<label [for]="id" [class]="labelClass">{{label}} <span *ngIf="required" class="red">*</span></label>
<div class="input-container">
  <span class="suggestions-trigger-icon {{triggerClass}}" (click)="toggleDropdown()"
        *ngIf="showTrigger">

    <ng-container *ngIf="dropdownShow; else hidden">
      <mat-icon>arrow_drop_up</mat-icon>
    </ng-container>

    <ng-template #hidden>
       <mat-icon>arrow_drop_down</mat-icon>
    </ng-template>

  </span>
  <input [tabindex]="tabindex" [id]="id" #input [ngModel]="value" 
        (ngModelChange)="inputValueChanged($event)"
        [placeholder]="dropdownShow ? placeholderOpen : placeholder"
        [ngClass]="{'suggests': dropdownShow, 'top-view': !bottomView, 'input-disabled': disabled}"
        (click)="showTrigger ? toggleDropdown() : openDropdown()"
        [readonly]="readonly"
        ngDefaultControl
        [disabled]="disabled"
        autocomplete="off">
</div>
<ng-template #dropdown>
  <div class="suggestions-pane">
    <div class="suggestions-container" [ngClass]="{'short': shortDropdown}"
         [ngStyle]="{'max-height.px': suggestionsContainerHeight}">
      <div class="suggestions-list" #suggestionList>
        <ng-content *ngTemplateOutlet="dropdownTemplate"></ng-content>
      </div>
      <div class="suggestions-container-gradient"></div>
    </div>
    <div class="suggestions-footer" *ngIf="dropdownFooter">
      <ng-content *ngTemplateOutlet="dropdownFooter"></ng-content>
    </div>
  </div>
  <div class="filler">&nbsp;</div>
</ng-template>
