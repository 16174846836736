import {ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NotificationsService} from '../notifications.service';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {NotificationMessageModel} from '../models/notifications/notification-message.model';
import {NotificationSystemModel} from '../models/notifications/notification-system.model';
import {NotificationCancelledCallModel} from '../models/notifications/notification-cancelled-call.model';
import {UserModel} from '../../models/user.model';
import {PushNotificationIncomingCallModel} from '../models/push-notifications/push-notification-incoming-call.model';
import {NotificationModel} from '../models/notifications/notification.model';
import {skip} from 'rxjs/operators';
import {CallApiService} from '../../api/call-api.service';

@Component({
  selector: 'atk-notifications-container',
  templateUrl: './notifications-container.component.html',
  styleUrls: ['./notifications-container.component.scss'],
  animations: [
    trigger('notificationShowClose', [
      state('show', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({opacity: 0}))
      ]),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsContainerComponent implements OnInit {

  @ViewChild('call', {static: false}) incomingCallModal: SwalComponent;
  notifications: (
    NotificationMessageModel |
    NotificationCancelledCallModel |
    NotificationSystemModel
    )[] = [];

  incomingCall: {
    user: UserModel,
    message: PushNotificationIncomingCallModel,
    isVideo: boolean
  } = null;
  dialingSound: HTMLAudioElement;

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private ref: ChangeDetectorRef,
    private zone: NgZone,
    private callService: CallApiService
  ) {
  }

  ngOnInit() {
    this.notificationsService.init();

    this.notificationsService.notificationQueue$.subscribe(notifications => {
      if (notifications) {
        const newMessagesToAdd = notifications.filter(item => this.notifications.findIndex(currentMessage => currentMessage.id === item.id) === -1);
        const oldMessagesToRemove = this.notifications.filter(item => notifications.findIndex(nextMessage => nextMessage.id === item.id) === -1);

        const messageIndexToRemove = oldMessagesToRemove.map(item => item.id);
        this.notifications = this.notifications.filter(item => !messageIndexToRemove.includes(item.id));

        this.notifications.unshift(...newMessagesToAdd);
        this.ref.markForCheck();
      }
    })

    this.notificationsService.incomingCallPopup$.pipe(skip(1)).subscribe(showPopupOptions => {
      if (showPopupOptions.show) {
        this.playCallingSound();
        this.openIncomingCallPopup(showPopupOptions.user, showPopupOptions.roomId, showPopupOptions.video);
      } else {
        this.stopCallingSound();
        this.incomingCallModal.close().then();
      }
    });
  }


  notificationClosed(notificationId: number) {
    this.notificationsService.removeNotificationsFromQueue(notificationId);
  }

  notificationClick(notification: NotificationModel) {
    if (notification.notificationType === 'message') {
      console.log(notification);
      this.zone.run(() => {
        this.router.navigate(['chat'], {
          state: {
            roomToChatWith: (notification as NotificationMessageModel).roomId
          }
        }).then();
      });
      this.notificationClosed(notification.id);
    }
  }

  redialClick(event: {
    callType: 'audio' | 'video'
  }, notification: NotificationModel) {
    this.router.navigate(['chat', 'call', (notification as NotificationCancelledCallModel).userId], {
      queryParams: { start: true,  video: event.callType === 'video' }}).then();
    this.notificationClosed(notification.id);
  }

  markAsReadClick(notification: NotificationModel) {
    this.notificationClosed(notification.id);
  }

  openIncomingCallPopup(user: UserModel, roomId: string, isVideo: boolean) {
    this.incomingCall = {
      user,
      message: null,
      isVideo
    }

    this.incomingCallModal.swalOptions = {
      allowOutsideClick: false,
      width: 280,
      showConfirmButton: false,
      position: 'top-right',
      customClass: {
        content: 'p-0'
      }
    };
    this.incomingCallModal.fire().then(dialogResult => {
      if (dialogResult.isConfirmed) {
        if (dialogResult.value.answer) {
          this.router.navigate(['chat', 'call', user.id], {
            queryParams: {
              receive: roomId,
              video: isVideo
            }
          }).then()
        } else {
          this.callService.ignoreCall({
            roomId
          }).subscribe(response => {
            console.log('ignore call reposen', response);

          })
        }
      }
      if (dialogResult.isDismissed) {
        this.notificationsService.setIncomingCallPopup({
          show: false,
          user: null,
          video: false,
          roomId: ''
        });
      }
    });
    this.ref.markForCheck();
  }

  incomingCallAnswered() {
    this.stopCallingSound();
    this.incomingCallModal.close({
      isDenied: false,
      isConfirmed: true,
      isDismissed: false,
      value: {
        answer: true
      }
    }).then();
  }

  incomingCallDismissed() {
    this.stopCallingSound();
    this.incomingCallModal.close({
      isDenied: false,
      isConfirmed: true,
      isDismissed: false,
      value: {
        answer: false
      }
    }).then();

  }

  playCallingSound() {
    this.dialingSound = new Audio('assets/audio/calling-sound.mp3');
    this.dialingSound.addEventListener('canplaythrough', (event) => {
      this.dialingSound.play().then();
    });
    this.dialingSound.loop = true;
  }

  stopCallingSound() {
    if (this.dialingSound) {
      this.dialingSound.pause();
    }
  }
}
