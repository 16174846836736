import {NgGapiClientConfig} from "ng-gapi";

export const gapiClientConfig: NgGapiClientConfig = {
    client_id: "89003400663-fm3g3tk0gu7gkf7e9urlpc8j32ihd6u7.apps.googleusercontent.com",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ],
    scope: [
      "https://www.googleapis.com/auth/calendar",
      "https://www.googleapis.com/auth/calendar.readonly"
    ].join(" ")
};

export const GOOGLE_SESSION_STORAGE_KEY = 'atk-google-auth';
