export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyANJbG3u8gcVv6JBmuihYsru3RVEDdMdlM',
    authDomain: 'appotek-7ac89.firebaseapp.com',
    databaseURL: 'https://appotek-7ac89.firebaseio.com',
    projectId: 'appotek-7ac89',
    storageBucket: 'appotek-7ac89.appspot.com',
    messagingSenderId: '57597666984',
    appId: '1:57597666984:web:cd1e1d8ad727cad51e849d'
  },
  BASE_URL: 'https://api2.appotek.com:3001',
  BASE_CALL_URL: 'https://api2.appotek.com:4001',
  WSS_URL: 'wss://api2.appotek.com:4001/ws',
  WSS_POST_URL: 'https://api2.appotek.com:4001',
  ISE_SERVER_REQUEST_URL: 'https://api2.appotek.com:4001/api/v1/ice-servers',
  API_KEY: 'AIzaSyAj97OMp53vp4Gc8bPLEexSHpkI5vw79YY',
  WSS_MESSENGER: 'wss://api2.appotek.com:3001/wss/messenger',
  WSS_WAITING_AREA_URL: 'wss://api2.appotek.com:3001/wss/clinics',
  AWS_IDENTITY_POOL_ID: 'eu-central-1:2263ba14-39ce-4b34-8d15-aef31b93fc79',
  AWS_REGION: 'eu-central-1',
  AWS_MQTT_ID: 'a397ub5veul0za-ats',
  AWS_PUB_SUB_ENV: 'dev'
};
