import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppGuard} from './app.guard';
import {CheckPaymentGuard} from "./core/guards/check-payment.guard";
import {ClinicGuard} from "./core/guards/clinic.guard";
import {LicenseGuard} from "./core/guards/license.guard";
import {IsAdminGuard} from "./core/guards/is-admin.guard";
import {AppComponent} from './app.component';

const ROUTES: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AppGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [AppGuard, ClinicGuard, IsAdminGuard]
  },
  {
    path: 'authorization',
    loadChildren: () => import('./authorization/authorization.module').then(m => m.AuthorizationModule)
  },
  {
    path: 'patients',
    loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule),
    canActivate: [AppGuard, ClinicGuard, LicenseGuard]
  },
  {
    path: 'media',
    loadChildren: () => import('./media/media.module').then(m => m.MediaModule),
    canActivate: [AppGuard, CheckPaymentGuard, ClinicGuard, LicenseGuard],
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar-events/calendar-events.module').then(m => m.CalendarEventsModule),
    canActivate: [AppGuard, CheckPaymentGuard, ClinicGuard, LicenseGuard]
  },
  {
    path: 'treatments',
    loadChildren: () => import('./treatments/treatments.module').then(m => m.TreatmentsModule),
    canActivate: [AppGuard, CheckPaymentGuard, ClinicGuard, LicenseGuard]
  },
  // {
  //   path: 'calls',
  //   loadChildren: () => import('./calls/calls.module').then(m => m.CallsModule),
  //   canActivate: [AppGuard, CheckPaymentGuard]
  // },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
    canActivate: [AppGuard, CheckPaymentGuard, ClinicGuard, LicenseGuard]
  },
  {
    path: 'waiting-rooms',
    loadChildren: () => import('./waiting-area/waiting-area.module').then(m => m.WaitingAreaModule),
    canActivate: [AppGuard, CheckPaymentGuard, ClinicGuard, LicenseGuard]
  },
  {
    path: 'monitoring',
    loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule),
    canActivate: [AppGuard, CheckPaymentGuard, ClinicGuard, LicenseGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AppGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications-sheet/notifications-sheet.module')
      .then(m => m.NotificationsSheetModule),
    canActivate: [AppGuard, ClinicGuard, LicenseGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
  providers: [AppGuard, ClinicGuard]
})
export class AppRoutingModule {

}

