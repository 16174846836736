<div class="paginator-container">
  <mat-select *ngIf="showCountChoose" [placeholder]="'shared.showAll' | translate" [(value)]="perPage" (valueChange)="pageChanged()" class="per-page">
    <mat-option [value]="perPageItem" *ngFor="let perPageItem of pageSizes">{{perPageItem}} {{'shared.perPage' | translate}}</mat-option>
    <mat-option [value]="'all'">{{'shared.showAll' | translate}}</mat-option>
  </mat-select>
  <atk-pagination
    [page]="page"
    (pageChange)="pageChanged($event)"
    [collectionSize]="count"
    [pageSize]="perPage">
  </atk-pagination>
</div>
